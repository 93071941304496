import React from 'react';

import styles from './TextInput.module.scss';

interface IProps {
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
}

const TextInput: React.FC<IProps> = ({ value, placeholder, className = '', onChange }) => {
  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event?.target?.value);
    }
  };

  return (
    <input
      type="text"
      placeholder={placeholder}
      defaultValue={value}
      className={`${styles.input} ${className}`}
      onChange={handleOnChange}
    />
  );
};

export default TextInput;
