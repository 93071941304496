import { SearchResult } from '../../../../../../state/models/searchResult.model';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { SearchResults } from '../../../../../../components';
import { GET_PRODUCT_RECOMMENDATIONS } from '../../../../../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import { getProductImageUrl } from '../../../../../../common/util';
import { IProductListItem } from '../../../../../product/components/ProductListItem/ProductListItem';

interface IProps {
  onClick?: (item: SearchResult) => void;
}

const RecommendedProductList: React.FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation(['common']);

  const { loading, error, data } = useQuery(GET_PRODUCT_RECOMMENDATIONS);

  if (loading) {
    return <SearchResults headline={t(`common:search.suggestions.headline`)} isLoading={true} />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const recommendations: IProductListItem[] = data?.getProductRecommendations?.map(
    (recommendation: any) => {
      return {
        product: {
          id: recommendation?.id ? recommendation.id : '',
          accountId: recommendation?.accountId ? recommendation.accountId : '',
          name: recommendation?.name ? recommendation?.name : '',
          brand: recommendation?.brand ? recommendation?.brand : undefined,
          gtin: recommendation?.gtin ? recommendation?.gtin : undefined,
          description: recommendation?.description ? recommendation?.description : undefined,
          defaultImageId: recommendation?.defaultImageId
            ? recommendation.defaultImageId
            : undefined,
          defaultImageUrl:
            recommendation?.id && recommendation?.defaultImageId
              ? getProductImageUrl(recommendation.id, recommendation.defaultImageId, 120)
              : undefined,
        },
      };
    }
  );

  return (
    <SearchResults
      headline={t(`common:search.suggestions.headline`)}
      items={recommendations}
      onClick={onClick}
    />
  );
};

export default RecommendedProductList;
