import React from 'react';
import StoreModel from '../../models/store.model';
import StoreListItem from '../StoreListItem';

import styles from './StoreList.module.scss';

interface IProps {
  stores: StoreModel[];
  className?: string;
  children?: React.ReactNode;
}

const StoreList: React.FC<IProps> = ({ stores, className = '', children }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {stores.map((store) => {
        return (
          <div key={store.id}>
            <StoreListItem store={store} />
            <hr />
          </div>
        );
      })}
      {stores.length === 0 && <>{children}</>}
    </div>
  );
};

export default StoreList;
