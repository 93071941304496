import { appConfig } from '../common/config';
import { AUTH_TYPE, AWSAppSyncClientOptions } from 'aws-appsync';
import Auth from '@aws-amplify/auth';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

const API_BASE_PATH = appConfig.graphqlBasePath;

const versionMiddleware = new ApolloLink((operation, forward) => {
  // add the current app version to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-ordist-version': appConfig.version || null,
      'x-ordist-client': 'WEB_APP' || null,
    },
  }));

  return forward(operation);
});

const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const clientOptions: AWSAppSyncClientOptions = {
    url: `https://${API_BASE_PATH}/graphql`,
    region: 'eu-central-1',
    auth: {
      type: AUTH_TYPE.AWS_IAM,
      credentials: () => Auth.currentCredentials(),
    },
    disableOffline: true,
  };

  const client = new ApolloClient({
    link: ApolloLink.from([
      versionMiddleware,
      (createAuthLink(clientOptions) as unknown) as ApolloLink,
      createSubscriptionHandshakeLink(clientOptions),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  return client;
};

export default getApolloClient;
