import React from 'react';

import styles from './SubmitButton.module.scss';

export interface ISubmitButton {
  children?: React.ReactNode;
  isCTA?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
}

export const SubmitButton: React.FC<ISubmitButton> = ({
  children,
  isCTA = false,
  isDisabled = false,
  isLoading = false,
  className = '',
  onClick,
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const styleType = isCTA ? styles.action : styles.cancel;

  return (
    <button
      className={`${styles.button} ${styleType} ${className}`}
      type="submit"
      onClick={handleOnClick}
      disabled={isDisabled}
    >
      {isLoading ? <span className={styles.spinner} /> : children}
    </button>
  );
};

export default SubmitButton;
