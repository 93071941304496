import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderItem } from '../OrderItem';
import styles from './OrderItemList.module.scss';
import { OrderLineItem } from '../../../../state/models/orderLineItem.model';
import { formatPrice } from '../../../../lib/format.extensions';

interface IProps {
  items: OrderLineItem[];
  onChangeQuantity?: (id: string, quantity: number) => void;
  onDelete?: (id: string) => void;
  onClick?: (item: OrderLineItem) => void;
}

export interface TotalInfo {
  isApprox: boolean;
  total: number;
}

export const getTotalInfo = (items: OrderLineItem[]): TotalInfo => {
  let isApprox = false;
  let total = 0;

  items.forEach((item) => {
    if (item.price === undefined || item.isApproxPrice) {
      isApprox = true;
    }

    if (item.price) {
      total += item.quantity * item.price;
    }
  });

  return {
    isApprox,
    total,
  };
};

const OrderItemList: React.FC<IProps> = ({
  items,
  onChangeQuantity,
  onDelete = undefined,
  onClick = undefined,
}) => {
  const { t } = useTranslation(['common']);
  const [total, setTotal] = useState(0);
  const [isApprox, setIsApprox] = useState(false);

  useEffect(() => {
    const info = getTotalInfo(items);
    setTotal(info.total);
    setIsApprox(info.isApprox);
  }, [items]);

  const changeQuantity = (id: string, quantity: number) => {
    if (onChangeQuantity) {
      onChangeQuantity(id, quantity);
    }
  };

  const handleDelete = (id: string) => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleClick = (item: OrderLineItem) => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        {items.length === 0 && <div>{t(`order:cart.empty`)}</div>}
        {items.length > 0 &&
          items.map((item) => {
            if (item.isArchived) {
              return null;
            }

            return (
              <div key={item.id}>
                <OrderItem
                  item={item}
                  onChangeQuantity={changeQuantity}
                  onDelete={handleDelete}
                  onClick={handleClick}
                />
                <hr />
              </div>
            );
          })}
      </div>
      <div className={styles.container__total}>
        {t(`common:cart.total`)}: {isApprox && <span>≈</span>}
        {formatPrice(total)}
      </div>
    </div>
  );
};

export default OrderItemList;
