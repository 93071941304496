const formatPrice = (value: number, forceSigned = false): string => {
  if (!value) {
    value = 0;
  }

  const result = value.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const sign = forceSigned && value > 0 ? '+' : '';

  return `${sign}${result} €`;
};

export { formatPrice };
