import React from 'react';
import BadgeCheck from '../icons/BadgeCheck';

import styles from './Brand.module.scss';

export interface IBrand {
  name: string;
  isVerified?: boolean;
  className?: string;
}

export const Brand: React.FC<IBrand> = ({ name, isVerified = false, className = '' }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <span className={styles.brand}>{name}</span>
      {isVerified && <BadgeCheck className={`${styles.verified__icon}`} />}
    </div>
  );
};

export default Brand;
