import React from 'react';

interface IProps {
  className?: string;
}

const Home: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 22"
      className={className}
    >
      <path d="m18.967 6.667-6.5-5.699a3.25 3.25 0 0 0-4.334 0l-6.5 5.699A3.25 3.25 0 0 0 .55 9.115v9.468a3.25 3.25 0 0 0 3.25 3.25h13a3.25 3.25 0 0 0 3.25-3.25V9.104a3.25 3.25 0 0 0-1.083-2.437Zm-6.5 13H8.133V14.25a1.083 1.083 0 0 1 1.084-1.083h2.166a1.083 1.083 0 0 1 1.084 1.083v5.417Zm5.416-1.084a1.084 1.084 0 0 1-1.083 1.084h-2.167V14.25a3.25 3.25 0 0 0-3.25-3.25H9.217a3.25 3.25 0 0 0-3.25 3.25v5.417H3.8a1.084 1.084 0 0 1-1.083-1.084V9.104a1.083 1.083 0 0 1 .368-.812l6.5-5.688a1.083 1.083 0 0 1 1.43 0l6.5 5.688a1.083 1.083 0 0 1 .368.812v9.48Z" />
    </svg>
  );
};

export default Home;
