import React, { useEffect, useState } from 'react';
import { Link /*, navigate*/ } from '@reach/router';

import logo from './ordist-logo.min.svg';
import styles from './Header.module.scss';
import Guest from '../icons/Guest';
import Drawer from '../Drawer';
import { MenuPage } from '../../modules/common/pages';
import { useBreakpoint } from '../../hooks/useBreakpoint.hook';
import { useStateValue } from '../../state';
import Avatar from '../Avatar';
import KebabMenu from '../icons/KebabMenu';
// import Cart from '../icons/Cart';

interface IProps {
  baseRoute?: string;
  isDetailMode?: boolean;
  onMenuClick?: () => void;
}

const Header: React.FC<IProps> = ({ baseRoute = '/', isDetailMode = false, onMenuClick }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [menuWidth, setMenuWidth] = useState('');
  const breakpoint = useBreakpoint();
  const [{ user }] = useStateValue();

  useEffect(() => {
    switch (breakpoint) {
      case 'xs':
        setMenuWidth('80vw');
        break;
      case 'sm':
        setMenuWidth('60vw');
        break;
      case 'md':
        setMenuWidth('40vw');
        break;
      case 'lg':
        setMenuWidth('40vw');
        break;
      default:
        setMenuWidth('20vw');
    }
  }, [breakpoint, menuWidth]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleMenuClick = () => {
    if (onMenuClick) {
      onMenuClick();
    }
  };

  // const handleOnCart = () => {
  //   navigate('/orders');
  // };

  return (
    <>
      <div className="grid-container">
        <div className="grid-x margin-x">
          <div className="cell small-12">
            <header className={styles.header}>
              <nav>
                <Link to={baseRoute} className={styles.logo__wrapper}>
                  <img src={logo} className={styles.logo__image} alt="Ordist logo" />
                </Link>
              </nav>
              <nav className={styles.navigation}>
                {/* <div className={styles.navigation__icon} onClick={handleOnCart}>
                  <Cart />
                </div> */}
                <div className={styles.navigation__icon} onClick={toggleMenu}>
                  {!isDetailMode && !user.isSignedIn && <Guest />}
                  {!isDetailMode && user.isSignedIn && (
                    <>
                      {user.avatar && <Avatar src={user.avatar} />}
                      {!user.avatar && <Guest />}
                    </>
                  )}
                </div>
                {isDetailMode && (
                  <div onClick={handleMenuClick}>
                    <KebabMenu className={styles.navigation__icon} />
                  </div>
                )}
              </nav>
            </header>
          </div>
        </div>
      </div>
      <Drawer
        open={showMenu}
        placement={'right'}
        width={menuWidth}
        onClose={() => {
          setShowMenu(false);
        }}
      >
        <MenuPage
          onClick={() => {
            setShowMenu(false);
          }}
        />
      </Drawer>
    </>
  );
};

export default Header;
