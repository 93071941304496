import { Reducer } from 'react';
import { Action } from '../actions';
import { Store } from '../models/store.model';
import { readStoreById } from '../../services/store.service';

export const storeReducer: Reducer<Store, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'STORE_LOAD': {
      const store = readStoreById(payload.storeId);

      if (!store) {
        return state;
      }

      return store;
    }

    default:
      return state;
  }
};
