import React from 'react';

import LocationModel from '../../../../models/location.model';

import styles from './Location.module.scss';

interface IProps {
  item: LocationModel;
  isSelected?: boolean;
  onClick?: (item: LocationModel) => void;
}

const Location: React.FC<IProps> = ({ item, isSelected = false, onClick }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <>
      <div
        className={`${styles.container} ${isSelected && styles.container__selected}`}
        onClick={handleOnClick}
      >
        {item.message && <div className={styles.message}>{item.message}</div>}
        <div className={styles.title}>{item.title}</div>
        <div className={styles.description}>{item.description}</div>
        {item.address && (
          <div className={styles.container__address}>
            <div>{item.address.streetAddress}</div>
            <div>
              {item.address.postalCode} {item.address.city}
            </div>
          </div>
        )}
        {(item.email || item.telephone) && (
          <div className={styles.container__contact}>
            {item.email && <div>{item.email}</div>}
            {item.telephone && <div>{item.telephone}</div>}
          </div>
        )}
      </div>
    </>
  );
};

export default Location;
