import { Link } from '@reach/router';
import React from 'react';
import Avatar from '../Avatar';
import Guest from '../icons/Guest';

import styles from './Profile.module.scss';

interface IProps {
  username?: string;
  displayName?: string;
  bio?: string;
  website?: string;
  avatar?: string;
  className?: string;
}

const Profile: React.FC<IProps> = ({
  username,
  displayName,
  bio,
  website,
  avatar,
  className = '',
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.avatar__container}>
        <div className={styles.avatar__left}>
          {avatar && <Avatar className={styles.avatar__icon} src={avatar} />}
          {!avatar && <Guest className={styles.avatar__icon} />}
        </div>
        <div className={styles.avatar__right}>
          <div className={styles.name__container}>
            {username && <h2 className={styles.name__user}>{username}</h2>}
            {!username && displayName && <h2 className={styles.name__display}>{displayName}</h2>}
            <Link to="/profile/edit" className={`${styles.name__button}`}>
              Edit Profile
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.details__container}>
        {username && displayName && <div className={styles.details__name}>{displayName}</div>}
        {bio && <div className={styles.details__bio}>{bio}</div>}
        {website && (
          <div className={styles.details__website}>
            <a href={website} target="_blank" rel="noreferrer">
              {website}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
