import React from 'react';

import styles from './Button.module.scss';

export interface IButton {
  children?: React.ReactNode;
  isCTA?: boolean;
  isDisabled?: boolean;
  className?: string;
  onClick?: () => void;
}

export const Button: React.FC<IButton> = ({
  children,
  isCTA = false,
  isDisabled = false,
  className = '',
  onClick,
}) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const styleType = isCTA ? styles.action : styles.cancel;

  return (
    <button
      className={`${styles.button} ${styleType} ${className}`}
      type="button"
      onClick={handleOnClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default Button;
