import React, { useEffect, useState } from 'react';
import { useField } from 'formik';

import { Error, Hint, Label } from '..';

import styles from './FormItem.module.scss';

export interface IFormItem {
  name: string;
  label: string;
  id?: string;
  type?: string;
  placeholder?: string;
  hint?: string;
  isRequired?: boolean;
  className?: string;
}

export const FormItem: React.FC<IFormItem> = ({
  name,
  label,
  id,
  type = 'text',
  placeholder,
  hint,
  isRequired = false,
  className = '',
}) => {
  const [field, meta] = useField(name);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const isError = !!meta.error;

    if (isError !== showError && meta.touched) {
      setShowError(isError);
    }
  }, [meta]);

  return (
    <div className={`${styles.container} ${className}`}>
      <div className="form-row">
        <div className={`form-group col-sm-12`}>
          <Label htmlFor={name}>
            {label} {!isRequired && <span className={styles.optional}>(optional)</span>}
            <input
              id={id}
              name={name}
              type={type}
              placeholder={placeholder}
              className={`form-control ${styles.field} ${showError ? styles.field__invalid : ''}`}
              autoComplete="off"
              autoCapitalize="on"
              autoCorrect="off"
              onChange={field.onChange}
              value={field.value}
            />
            {showError ? <Error className={`${styles['mt-1']}`}>{meta.error}</Error> : null}
            {hint && <Hint className={`${showError ? '' : styles['mt-1']}`}>{hint}</Hint>}
          </Label>
        </div>
      </div>
    </div>
  );
};

export default FormItem;
