import React from 'react';

import ProductModel from '../../models/product.model';
import { ProductListItem } from '../ProductListItem';
import { IProductListItem } from '../ProductListItem/ProductListItem';

import styles from './ProductList.module.scss';

export interface IProductList {
  items?: IProductListItem[];
  className?: string;
  onClick?: (product: ProductModel) => void;
}

export const ProductList: React.FC<IProductList> = ({ items, className = '', onClick }) => {
  const handleOnClick = (product: ProductModel) => {
    if (onClick) {
      onClick(product);
    }
  };

  const handleAddToList = () => {
    return;
  };

  const handleFavoriteClick = () => {
    return;
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div>
        {!items || (items.length === 0 && <div>No Products available.</div>)}
        {items &&
          items.length > 0 &&
          items.map((item) => {
            return (
              <div key={item.product?.id}>
                <ProductListItem
                  product={item.product}
                  onClick={handleOnClick}
                  onFavoriteClick={handleFavoriteClick}
                  onAddToList={handleAddToList}
                  isVerified={item.isVerified}
                  checked={item.checked}
                />
                <hr className={`${styles.divider}`} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductList;
