import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { getProductImageUrl } from '../../../../../common/util';

import { SearchResults } from '../../../../../components';
import { SEARCH } from '../../../../../graphql/queries';
import { SearchResult } from '../../../../../state/models/searchResult.model';
import { IProductListItem } from '../../../../product/components/ProductListItem/ProductListItem';

interface IProps {
  headline?: string;
  term?: string;
  isLoading?: boolean;
  suggestItem?: boolean;
  onClick?: (item: SearchResult) => void;
}

const SearchProductList: React.FC<IProps> = ({
  headline = '',
  term = '',
  isLoading = false,
  suggestItem,
  onClick = undefined,
}) => {
  const { loading, error, data } = useQuery(SEARCH, {
    variables: { term: term },
  });

  if (loading) {
    return <SearchResults headline={'Search'} isLoading={true} suggestItem />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const items: IProductListItem[] = data?.search?.items?.map((search: any) => {
    return {
      product: {
        id: search?.id ? search.id : '',
        accountId: search?.accountId,
        name: search?.name ? search?.name : '',
        brand: search?.brand ? search?.brand : undefined,
        gtin: search?.gtin ? search?.gtin : undefined,
        description: search?.description ? search?.description : undefined,
        defaultImageId: search?.defaultImageId ? search.defaultImageId : undefined,
        defaultImageUrl:
          search?.id && search?.defaultImageId
            ? getProductImageUrl(search.id, search.defaultImageId, 120)
            : undefined,
      },
    };
  });

  return (
    <SearchResults
      headline={headline}
      term={term}
      items={items}
      isLoading={isLoading}
      suggestItem={suggestItem}
      onClick={onClick}
    />
  );
};

export default SearchProductList;
