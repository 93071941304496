import React from 'react';

interface IProps {
  className?: string;
}

const List: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 22"
      className={className}
    >
      <path d="M5.65 8.833h1.083a1.083 1.083 0 1 0 0-2.166H5.65a1.083 1.083 0 1 0 0 2.166Zm0 2.167a1.083 1.083 0 1 0 0 2.167h6.5a1.083 1.083 0 1 0 0-2.167h-6.5Zm11.917-3.315a1.415 1.415 0 0 0-.065-.293v-.097a1.158 1.158 0 0 0-.206-.303l-6.5-6.5a1.16 1.16 0 0 0-.303-.206.347.347 0 0 0-.098 0 .954.954 0 0 0-.357-.12H3.483a3.25 3.25 0 0 0-3.25 3.25v15.167a3.25 3.25 0 0 0 3.25 3.25h10.834a3.25 3.25 0 0 0 3.25-3.25V7.685Zm-6.5-3.824 2.806 2.806H12.15a1.083 1.083 0 0 1-1.083-1.084V3.861ZM15.4 18.583a1.083 1.083 0 0 1-1.083 1.084H3.483A1.083 1.083 0 0 1 2.4 18.583V3.417a1.083 1.083 0 0 1 1.083-1.084H8.9v3.25a3.25 3.25 0 0 0 3.25 3.25h3.25v9.75Zm-3.25-3.25h-6.5a1.083 1.083 0 1 0 0 2.167h6.5a1.084 1.084 0 0 0 0-2.167Z" />
    </svg>
  );
};

export default List;
