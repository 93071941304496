import { List } from '../models/list.model';
import { Reducer } from 'react';
import { Action } from '../actions';
import { ListItem } from '../models/listItem.model';

const calculateTotal = (items: ListItem[]) => {
  let result = 0;

  items.forEach((item) => {
    if (item.price) {
      result += item.quantity * item.price;
    }
  });

  return result;
};

const calculateQuantity = (items: ListItem[]) => {
  let result = 0;

  items.forEach((item) => {
    if (item.quantity) {
      result += item.quantity;
    }
  });

  return result;
};

export const listReducer: Reducer<List, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'LIST_ADD_ITEM': {
      const items = [payload.item, ...state.items];

      return {
        ...state,
        items,
        itemCount: calculateQuantity(items),
        itemTotal: calculateTotal(items),
      };
    }

    case 'LIST_SET_ITEM_QUANTITY': {
      const items = state.items.map((item) => {
        if (item.id === payload.id) {
          item.quantity = payload.quantity;
          return item;
        } else {
          return item;
        }
      });

      return {
        ...state,
        items,
        itemCount: calculateQuantity(items),
        itemTotal: calculateTotal(items),
      };
    }

    case 'LIST_TOGGLE_ITEM': {
      const items = [...state.items];

      return {
        ...state,
        items: items.map((item) => {
          if (item.id === payload.id) {
            item.isArchived = !item.isArchived;
            return item;
          } else {
            return item;
          }
        }),
        itemCount: calculateQuantity(items),
        itemTotal: calculateTotal(items),
      };
    }

    case 'LIST_DELETE_ITEM': {
      const items = state.items.filter((item) => item.id !== payload.id);

      return {
        ...state,
        items,
        itemCount: calculateQuantity(items),
        itemTotal: calculateTotal(items),
      };
    }

    case 'LIST_SETTINGS_SHOW_ITEMS_ALREADY_ON_ORDER': {
      return {
        ...state,
        showItemsAlreadyOnOrder: payload?.showItemsAlreadyOnOrder,
      };
    }

    default:
      return state;
  }
};
