import { Link } from '@reach/router';
import React from 'react';
import BadgeCheck from '../../../../components/icons/BadgeCheck';
import MapMarker from '../../../../components/icons/MapMarker';
import StoreModel from '../../models/store.model';

import styles from './StoreListItem.module.scss';

interface IProps {
  store: StoreModel;
  className?: string;
}

const StoreListItem: React.FC<IProps> = ({ store, className = '' }) => {
  return (
    <Link to={`/stores/${store.id}`}>
      <div className={`${styles.container} ${className}`}>
        <div className={`${styles.container} ${className}`}>
          <div className={styles.item}>
            <div className={`${styles.wrapper}`}>
              <div className={styles.title}>{store.name}</div>
              {store.verified && <BadgeCheck className={`${styles.verified__icon}`} />}
            </div>
            <div className={styles.description}>{store.description}</div>
            <div className={styles.details}>
              {store.distance && (
                <div className={`${styles.wrapper}`}>
                  <MapMarker className={styles.details__icon} />
                  <div className={styles.details__label}>
                    {store.distance.toFixed(0)} kilometers away
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StoreListItem;
