import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';

import styles from './Cart.module.scss';

interface IProps {
  quantity: number;
  total: number;
  onClick?: () => void;
}

const Cart: React.FC<IProps> = ({ quantity, total, onClick = undefined }: IProps) => {
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setHasChanged(true);
  }, [quantity]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <button
          className={`button small-only-expanded float-right ${styles.container__button} ${
            hasChanged && styles.animation
          }`}
          disabled={quantity === 0}
          onClick={handleClick}
          onAnimationEnd={() => setHasChanged(false)}
        >
          <span>
            Warenkorb (
            <CountUp
              start={0}
              end={total}
              preserveValue={true}
              duration={0.5}
              separator=" "
              decimals={2}
              decimal=","
              prefix=""
              suffix=" €"
            />
            )
          </span>
        </button>
      </div>
    </>
  );
};

export default Cart;
