import { User } from '../models/user.model';
import { Reducer } from 'react';
import { Action } from '../actions';

const getIdentity = (attributes: any) => {
  if (attributes?.identities) {
    const identities = JSON.parse(attributes?.identities);
    if (identities && identities[0].providerType) {
      return identities[0].providerType;
    }
  }

  return;
};

const getAvatar = (attributes: any, identity?: string) => {
  if (!identity) {
    return;
  }

  if (identity.toUpperCase() === 'GOOGLE' && attributes?.picture) {
    return attributes?.picture;
  }

  if (identity.toUpperCase() === 'FACEBOOK' && attributes?.picture) {
    try {
      const avatar = JSON.parse(attributes?.picture);
      return avatar?.data?.url;
    } catch (ex) {
      console.error(ex);
    }
  }

  return;
};

export const userReducer: Reducer<User, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'BOARD_SELECT': {
      return {
        ...state,
        selectedBoard: payload?.board,
      };
    }
    case 'ORDER_SELECT': {
      return {
        ...state,
        selectedOrder: payload?.order,
      };
    }
    case 'USER_LOGIN': {
      const identity = getIdentity(payload?.data?.attributes);
      const avatar = getAvatar(payload?.data?.attributes, identity);
      const displayName = payload?.data?.displayName;
      const username = payload?.data?.username;

      return {
        ...state,
        isSignedIn: true,
        avatar,
        identity,
        username,
        displayName,
      };
    }
    case 'USER_LOGOUT': {
      return {
        ...state,
        isSignedIn: false,
        avatar: undefined,
        identity: undefined,
        username: undefined,
        displayName: undefined,
      };
    }
    case 'USER_PROFILE_UPDATE': {
      return {
        ...state,
        displayName: payload?.displayName,
      };
    }
    default:
      return state;
  }
};
