import React from 'react';

interface IProps {
  className?: string;
}

const Profile: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 22"
      className={className}
    >
      <path d="M15.72 11.77a6.5 6.5 0 1 0-8.04 0 10.833 10.833 0 0 0-6.737 8.86 1.09 1.09 0 1 0 2.166.24 8.667 8.667 0 0 1 17.225 0 1.083 1.083 0 0 0 1.084.963h.119a1.083 1.083 0 0 0 .953-1.191 10.834 10.834 0 0 0-6.77-8.873ZM11.7 11a4.334 4.334 0 1 1 0-8.667 4.334 4.334 0 0 1 0 8.667Z" />
    </svg>
  );
};

export default Profile;
