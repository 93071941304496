import React, { useState, useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import { m as motion } from 'framer-motion';

import { MotionConfig } from 'framer-motion';

import { OrderLineItem } from '../../../../state/models/orderLineItem.model';
import { useStateValue } from '../../../../state';
import { ProductList } from '../../components/ProductList';
import { Product } from '../../../../state/models/product.model';
import { ProductOption as ProductOptionModel } from '../../../../state/models/productOption.model';
import { NotFoundPage } from '../../../common/pages/NotFoundPage';

import styles from '../../Store.module.scss';
import { Cart } from '../../components';
import SearchBar from '../../../../components/SearchBar';
import { Category } from '../../../../state/models/category.model';
import { Store } from '../../../../state/models/store.model';
import { ProductModal } from '../../components/ProductModal';

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
};

const backVariants = {
  exit: { x: 50, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
};

export const getTotal = (product: Product, options: ProductOptionModel[]): number => {
  let total = product.price ? product.price : 0;

  options.forEach((option) => {
    if (option.price) {
      total += option.price;
    }
  });

  return total;
};

interface IProps extends RouteComponentProps {
  storeId?: string;
}

const StoreOverviewPage: React.FC<IProps> = ({ storeId = undefined }) => {
  const [{ order, store }, dispatch] = useStateValue();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getFilteredProducts = (store: Store, searchTerm: string) => {
    if (!store || !store.products) {
      return [];
    }

    const categories: Category[] = [];
    const products: Product[] = [];

    store.products.forEach((product) => {
      if (!product.category) {
        products.push(product);
        return;
      }

      const category = categories.find((category) => {
        return product?.category?.id === category.id;
      });

      if (!category) {
        categories.push(product.category);
      }
    });

    categories.forEach((category) => {
      store?.products?.map((product) => {
        if (product?.category?.id === category.id) {
          products.push(product);
        }
      });
    });

    return products.filter((product) => {
      const synonyms = product.synonyms?.join().toLowerCase();

      return (
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        synonyms?.includes(searchTerm.toLowerCase())
      );
    });
  };

  useEffect(() => {
    if (store) {
      setFilteredProducts(getFilteredProducts(store, searchTerm));
    }
  }, [searchTerm, store]);

  if (store === undefined) {
    return <NotFoundPage />;
  }

  const findItemBySku = (sku: string): OrderLineItem | undefined => {
    return order?.lineItems?.find((item) => {
      if (item.sku === sku) {
        return item;
      }
      return;
    });
  };

  const handleAddProduct = (product: Product, quantity: number) => {
    if (product.options && product.options.length !== 0) {
      setSelectedProduct(product);
      openModal();
      return;
    }
    const cartItem = findItemBySku(product.sku);

    if (cartItem) {
      dispatch({
        type: 'SET_QUANTITY',
        payload: { id: cartItem.id, quantity: cartItem.quantity + quantity },
      });
      return;
    }

    const item: OrderLineItem = {
      id: `temp_${uuidv4()}`,
      product: product,
      sku: product.sku,
      title: product.name,
      subline: '',
      isArchived: false,
      updatedAt: new Date(),
      price: product.price ? product.price : undefined,
      quantity: quantity,
      isApproxPrice: false,
    };

    // ReactGA.event({
    //   category: 'Cart',
    //   action: `User added product '${item.title}' to the cart.`,
    // });

    dispatch({ type: 'ADD_ORDER', payload: { item } });
  };

  const handleAddProductWithOptions = (
    product: Product,
    options: ProductOptionModel[],
    quantity: number
  ) => {
    closeModal();

    const cartItem = findItemBySku(product.sku);

    if (cartItem) {
      dispatch({
        type: 'SET_QUANTITY',
        payload: { id: cartItem.id, quantity: cartItem.quantity + quantity },
      });
      return;
    }

    const item: OrderLineItem = {
      id: `temp_${uuidv4()}`,
      product: product,
      sku: product.sku,
      title: product.name,
      subline: '',
      isArchived: false,
      updatedAt: new Date(),
      price: product.price ? getTotal(product, options) : undefined,
      quantity: quantity,
      isApproxPrice: false,
      options: options,
    };

    // ReactGA.event({
    //   category: 'Cart',
    //   action: `User added product '${item.title}' to the cart.`,
    // });

    dispatch({ type: 'ADD_ORDER', payload: { item } });
  };

  const handleChangeSearchTerm = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const heroStyle = {
    backgroundImage: `url('${store.heroImage}')`,
  };

  return (
    <>
      <Helmet>
        <title>{store.name} | ordist.com</title>
      </Helmet>
      <ProductModal
        product={selectedProduct}
        showModal={showModal}
        onCloseModal={closeModal}
        onAddToCart={handleAddProductWithOptions}
      />
      <div>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            {store.heroImage && <div className={styles.container__hero} style={heroStyle} />}
            <MotionConfig>
              <motion.div initial="exit" animate="enter" exit="exit" variants={backVariants}>
                <h1>{store.name}</h1>
              </motion.div>
            </MotionConfig>
          </div>
          <div className={styles.container}>
            <SearchBar
              value={searchTerm}
              onChangeSearchTerm={handleChangeSearchTerm}
              showIcon={true}
              translations={{ placeholder: store.searchPlaceholder }}
            />
          </div>
          <div className={styles.container}>
            <ProductList products={filteredProducts} onAddToCart={handleAddProduct} />
          </div>
        </div>
        {order.lineItemCount > 0 && (
          <Cart
            quantity={order.lineItemCount}
            total={order.lineItemTotal}
            onClick={() => navigate(`/stores/${storeId}/cart`)}
          />
        )}
      </div>
    </>
  );
};

export default StoreOverviewPage;
