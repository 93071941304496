import { Reducer } from 'react';
import { Action } from '../actions';
import { State } from '../models';
import { orderReducer } from './order.reducer';
import { pwaReducer } from './pwa.reducer';
import { listReducer } from './list.reducer';
import { storeReducer } from './store.reducer';
import { userReducer } from './user.reducer';
import { appReducer } from './app.reducer';
import { List } from '../models/list.model';
import { User } from '../models/user.model';

const storeUser = (user: User) => {
  if (!user) {
    return;
  }

  const storageName = `user`;

  localStorage.setItem(
    storageName,
    JSON.stringify({
      displayName: user.displayName,
      username: user.username,
      selectedBoard: user.selectedBoard,
    })
  );
};

const storeList = (list: List) => {
  if (!list) {
    return;
  }

  const storageName = `list_my`;

  localStorage.setItem(storageName, JSON.stringify(list));
};

export const mainReducer: Reducer<State, Action> = (
  { pwa, order, list, store, user, app },
  action
) => {
  const result = {
    order: orderReducer(order, action),
    pwa: pwaReducer(pwa, action),
    list: listReducer(list, action),
    store: storeReducer(store, action),
    user: userReducer(user, action),
    app: appReducer(app, action),
  };

  storeUser(result.user);
  storeList(result.list);

  return result;
};
