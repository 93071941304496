import React from 'react';

interface IProps {
  className?: string;
}

const Barcode: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="barcode-read"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 22"
    >
      <path
        fill="currentColor"
        d="M26 1v5a1 1 0 0 1-2 0V2h-4a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1ZM6 20H2v-4a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2Zm19-5a1 1 0 0 0-1 1v4h-4a1 1 0 0 0 0 2h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1ZM1 7a1 1 0 0 0 1-1V2h4a1 1 0 0 0 0-2H1a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Zm6-2a1 1 0 0 0-1 1v10a1 1 0 1 0 2 0V6a1 1 0 0 0-1-1Zm13 11V6a1 1 0 0 0-2 0v10a1 1 0 0 0 2 0ZM15 5a1 1 0 0 0-1 1v10a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1Zm-4 0a1 1 0 0 0-1 1v10a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1Z"
      />
    </svg>
  );
};

export default Barcode;
