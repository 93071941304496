import React from 'react';
import { Button } from '..';
import { SubmitButton } from '../SubmitButton';

import styles from './ActionOrCancel.module.scss';

interface IProps {
  actionLabel?: string;
  actionDisabled?: boolean;
  cancelLabel?: string;
  isLoading?: boolean;
  className?: string;
  onAction?: () => void;
  onCancel?: () => void;
}

const ActionOrCancel: React.FC<IProps> = ({
  actionLabel = 'Save',
  cancelLabel = 'Abbrechen',
  actionDisabled = false,
  isLoading = false,
  className = '',
  onAction,
  onCancel,
}) => {
  const handleOnAction = () => {
    if (onAction) {
      onAction();
    }
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <SubmitButton
        isCTA={true}
        isDisabled={actionDisabled}
        isLoading={isLoading}
        onClick={handleOnAction}
      >
        {actionLabel}
      </SubmitButton>
      <Button className={`${styles['mt-2']}`} onClick={handleOnCancel}>
        {cancelLabel}
      </Button>
    </div>
  );
};

export default ActionOrCancel;
