import React from 'react';

import styles from './LeftRightLayout.module.scss';

export interface ILeftRightLayout {
  left?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
}

export const LeftRightLayout: React.FC<ILeftRightLayout> = ({ left, right, className = '' }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.container__left}>{left}</div>
      <div className={styles.container__right}>{right}</div>
    </div>
  );
};

export default LeftRightLayout;
