import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { readStoreById } from '../../../../services/store.service';
import { OrderLineItem } from '../../../../state/models/orderLineItem.model';

import styles from '../../Store.module.scss';

interface IProps extends RouteComponentProps {
  storeId?: string;
}

const StorePaymentPage: React.FC<IProps> = ({ storeId = undefined }) => {
  if (!storeId) {
    return <></>;
  }

  const store = readStoreById(storeId);

  if (store === undefined) {
    return <></>;
  }

  const items: OrderLineItem[] = [];

  store.products?.map((product) => {
    items.push({
      id: product.id,
      title: product.name,
      subline: '',
      quantity: 0,
      price: product.price ? product.price : 0.0,
      isApproxPrice: false,
    });
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className="grid-container">
            <div className="grid-x margin-x">
              <div className="cell small-12 medium-offset-2 medium-8">
                <h1>Zahlungsart</h1>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div>
            Angabe der Zahlungsmethoden
            <br />
            <br />
            z.B. Bar, Rechnung oder PayPal
          </div>
          <div>
            <button
              className="button small-only-expanded float-right"
              onClick={() => navigate(`/stores/${storeId}/review`)}
            >
              Weiter
            </button>
            <button
              className="button hollow small-only-expanded float-left"
              onClick={() => navigate(`/stores/${storeId}/personal-data`)}
            >
              Zurück zu Persönliche Daten
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorePaymentPage;
