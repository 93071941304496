import React from 'react';

import styles from './ProductDetails.module.scss';
import { ProductOptionList } from '..';
import { Product } from '../../../../state/models/product.model';
import { ProductOption as ProductOptionModel } from '../../../../state/models/productOption.model';

interface IProps {
  product: Product;
  selectedOptions?: ProductOptionModel[];
  onChooseOption?: (option: ProductOptionModel, checked: boolean) => void;
}

const ProductDetails: React.FC<IProps> = ({
  product,
  selectedOptions = undefined,
  onChooseOption = undefined,
}) => {
  if (!product) {
    return <></>;
  }

  const handleChooseOption = (option: ProductOptionModel, checked: boolean) => {
    if (onChooseOption) {
      onChooseOption(option, checked);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className="cell">{product.name}</div>
        <div className="cell">{product.description}</div>
        <div className="cell">
          <ProductOptionList
            options={product.options ? product.options : []}
            selectedOptions={selectedOptions}
            onChooseOption={handleChooseOption}
          />
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
