import React from 'react';
import { navigate } from '@reach/router';

// import Favorite from './components/Favorite';
import Home from '../icons/Home';
import List from '../icons/List';
import Product from '../icons/Product';
import Profile from '../icons/Profile';
import { BottomNavigationAction } from '../BottomNavigationAction';

import styles from './BottomNavigation.module.scss';
import { useTranslation } from 'react-i18next';

export interface IBottomNavigation {
  location?: string;
  className?: string;
  onClick?: (location: string) => void;
}

export const BottomNavigation: React.FC<IBottomNavigation> = ({
  location = '/',
  className = '',
  onClick,
}) => {
  const { t } = useTranslation(['common']);

  const handleClick = (location: string) => {
    if (onClick) {
      onClick(location);
    }
    navigate(location);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <BottomNavigationAction
        icon={<Home />}
        label={t(`common:navigation.home`)}
        isSelected={location === '/'}
        navigateTo={'/'}
        onClick={handleClick}
      />
      <BottomNavigationAction
        icon={<List />}
        label={t(`common:navigation.lists`)}
        isSelected={location.startsWith('/boards')}
        navigateTo="/boards"
        onClick={handleClick}
      />
      {/* <BottomNavigationAction icon={<Favorite />} label={t(`common:navigation.favorites`)} /> */}
      <BottomNavigationAction
        icon={<Product />}
        label={t(`common:navigation.products`)}
        isSelected={location.startsWith('/products')}
        navigateTo="/products"
        onClick={handleClick}
      />
      <BottomNavigationAction
        icon={<Profile />}
        label={t(`common:navigation.profile`)}
        isSelected={location.startsWith('/profile')}
        navigateTo="/profile"
        onClick={handleClick}
      />
    </div>
  );
};

export default BottomNavigation;
