import { appConfig } from '../config';

const apiBasePath = appConfig.apiBasePath;

const getProductImageUrl = (productId: string, imageId: string, width: number): string => {
  const imageUrl = `https://${apiBasePath}/media/image/products/${productId}/${width}x_${imageId}.png`;
  return imageUrl;
};

export { getProductImageUrl };
