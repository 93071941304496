import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from '../../../../state/models/product.model';
import { ProductOption as ProductOptionModel } from '../../../../state/models/productOption.model';
import { ProductDetails } from '../ProductDetails';
import { Modal, Quantity } from '../../../../components';
import { formatPrice } from '../../../../lib/format.extensions';

import styles from './ProductModal.module.scss';

export const getTotal = (product: Product, options: ProductOptionModel[], quantity = 1): number => {
  let total = product.price ? product.price : 0;

  options.forEach((option) => {
    if (option.price) {
      total += option.price;
    }
  });

  return total * quantity;
};

interface IProps {
  product?: Product;
  showModal?: boolean;
  onCloseModal?: () => void;
  onAddToCart?: (product: Product, options: ProductOptionModel[], quantity: number) => void;
}

const ProductModal: React.FC<IProps> = ({
  product,
  showModal = true,
  onCloseModal = undefined,
  onAddToCart = undefined,
}) => {
  const { t } = useTranslation(['common']);
  const [total, setTotal] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<ProductOptionModel[]>([]);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (product) {
      const total = getTotal(product, selectedOptions, quantity);
      setTotal(total);
    }
  }, [product, selectedOptions, quantity]);

  const reset = () => {
    setQuantity(1);
    setSelectedOptions([]);
  };

  useEffect(() => {
    if (showModal) {
      reset();
    }
  }, [showModal]);

  const handleCloseModal = () => {
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const handleAddToCart = () => {
    if (product && onAddToCart) {
      onAddToCart(product, selectedOptions, quantity);
    }
  };

  const handleChooseOption = (option: ProductOptionModel, checked: boolean) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, option]);
    }
    if (!checked) {
      setSelectedOptions([...selectedOptions.filter((item) => item.id !== option.id)]);
    }
  };

  const handleChangeQuantity = (quantity: number) => {
    setQuantity(quantity);
  };

  return (
    <>
      <div className={styles.container}>
        <Modal title={t(`store:product.options.edit`)} show={showModal} onClose={handleCloseModal}>
          {product && (
            <div className="grid-container">
              <div className="grid-x margin-x">
                <div className="small-12">
                  <div className={styles.modal__main}>
                    <ProductDetails product={product} onChooseOption={handleChooseOption} />
                    <div className={styles.container__quantity}>
                      <div className={styles.modal__quantity}>
                        <Quantity
                          quantity={quantity}
                          min={1}
                          removeOnMin={false}
                          onChangeQuantity={handleChangeQuantity}
                        />
                      </div>
                    </div>
                    <div className={styles.container__total}>
                      {t(`common:cart.total`)}: {formatPrice(total)}
                    </div>
                    <div className={styles.container__buttons}>
                      <button
                        className="button small-only-expanded float-right"
                        onClick={handleAddToCart}
                      >
                        {t(`store:buttons.add`)}
                      </button>
                      <button
                        className="button hollow small-only-expanded float-left"
                        onClick={handleCloseModal}
                      >
                        {t(`common:buttons.cancel`)}
                      </button>
                    </div>
                  </div>
                  <div className={styles.scrollsafe} />
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default ProductModal;
