import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import homeEN from './locales/home/en.json';
import homeDE from './locales/home/de.json';
// import homeES from './locales/home/es.json';
// import homeIT from './locales/home/it.json';
// import homeTR from './locales/home/tr.json';
import authEN from './locales/auth/en.json';
import authDE from './locales/auth/de.json';
import boardEN from './locales/board/en.json';
import boardDE from './locales/board/de.json';
// import boardES from './locales/board/es.json';
// import boardIT from './locales/board/it.json';
// import boardTR from './locales/board/tr.json';
import orderEN from './locales/order/en.json';
import orderDE from './locales/order/de.json';
// import orderES from './locales/order/es.json';
// import orderIT from './locales/order/it.json';
// import orderTR from './locales/order/tr.json';
import storeEN from './locales/store/en.json';
import storeDE from './locales/store/de.json';
// import storeES from './locales/store/es.json';
// import storeIT from './locales/store/it.json';
// import storeTR from './locales/store/tr.json';
import fulfillmentEN from './locales/fulfillment/en.json';
import fulfillmentDE from './locales/fulfillment/de.json';
// import fulfillmentES from './locales/fulfillment/es.json';
// import fulfillmentIT from './locales/fulfillment/it.json';
// import fulfillmentTR from './locales/fulfillment/tr.json';
import shareEN from './locales/share/en.json';
import shareDE from './locales/share/de.json';
// import shareES from './locales/share/es.json';
// import shareIT from './locales/share/it.json';
// import shareTR from './locales/share/tr.json';
import commonEN from './locales/common/en.json';
import commonDE from './locales/common/de.json';
// import commonES from './locales/common/es.json';
// import commonIT from './locales/common/it.json';
// import commonTR from './locales/common/tr.json';
import footerEN from './locales/footer/en.json';
import footerDE from './locales/footer/de.json';
// import footerES from './locales/footer/es.json';
// import footerIT from './locales/footer/it.json';
// import footerTR from './locales/footer/tr.json';
import productEN from './locales/product/en.json';
import productDE from './locales/product/de.json';
// import productES from './locales/product/es.json';
// import productIT from './locales/product/it.json';
// import productTR from './locales/product/tr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        common: commonEN,
        home: homeEN,
        auth: authEN,
        board: boardEN,
        store: storeEN,
        order: orderEN,
        fulfillment: fulfillmentEN,
        share: shareEN,
        footer: footerEN,
        product: productEN,
      },
      de: {
        common: commonDE,
        home: homeDE,
        auth: authDE,
        board: boardDE,
        store: storeDE,
        order: orderDE,
        fulfillment: fulfillmentDE,
        share: shareDE,
        footer: footerDE,
        product: productDE,
      },
      // es: {
      //   common: commonES,
      //   home: homeES,
      //   board: boardES,
      //   store: storeES,
      //   order: orderES,
      //   fulfillment: fulfillmentES,
      //   share: shareES,
      //   footer: footerES,
      //   product: productES,
      // },
      // it: {
      //   common: commonIT,
      //   home: homeIT,
      //   board: boardIT,
      //   store: storeIT,
      //   order: orderIT,
      //   fulfillment: fulfillmentIT,
      //   share: shareIT,
      //   footer: footerIT,
      //   product: productIT,
      // },
      // tr: {
      //   common: commonTR,
      //   home: homeTR,
      //   board: boardTR,
      //   store: storeTR,
      //   order: orderTR,
      //   fulfillment: fulfillmentTR,
      //   share: shareTR,
      //   footer: footerTR,
      //   product: productTR,
      // },
    },

    fallbackLng: 'en',
    debug: false,

    ns: [
      'common',
      'home',
      'auth',
      'board',
      'store',
      'order',
      'fulfillment',
      'share',
      'footer',
      'product',
    ],
    defaultNS: 'common',

    keySeparator: '.',

    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
