import React from 'react';
import Placeholder from '../icons/Placeholder';

import styles from './Image.module.scss';

export interface IImage {
  url?: string;
  alt?: string;
  className?: string;
  onClick?: () => void;
}

export const Image: React.FC<IImage> = ({ url, alt = '', className = '', onClick }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      {url ? (
        <img className={styles.image} src={url} alt={alt} onClick={handleOnClick} />
      ) : (
        <div className={styles.image} onClick={handleOnClick}>
          <Placeholder className={styles.placeholder} />
        </div>
      )}
    </div>
  );
};

export default Image;
