import React from 'react';

interface IProps {
  className?: string;
}

const KebabMenu: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6 22"
      className={className}
    >
      <path
        fill="currentColor"
        d="M3 5.167A2.333 2.333 0 1 0 3 .5a2.333 2.333 0 0 0 0 4.667Zm0 11.666A2.333 2.333 0 1 0 3 21.5a2.333 2.333 0 0 0 0-4.667Zm0-8.166a2.333 2.333 0 1 0 0 4.666 2.333 2.333 0 0 0 0-4.666Z"
      />
    </svg>
  );
};

export default KebabMenu;
