import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Helmet } from 'react-helmet';

import styles from '../../../store/Store.module.scss';

const NotFoundPage: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found | ordist.com</title>
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className="grid-container">
            <div className="grid-x margin-x">
              <div className="cell small-12 medium-offset-2 medium-8">
                <h1>Page Not Found</h1>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div className="grid-container">
            <div className="grid-x margin-x">
              <div className="cell small-12 medium-offset-2 medium-8">
                <div>The page you are looking for, does not exist.</div>
                <div>
                  <p></p>
                  <button
                    className="button hollow small-only-expanded"
                    onClick={() => navigate('/')}
                  >
                    Back to Homepage
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
