import React, { useEffect, useState } from 'react';
import Drawer from 'rc-drawer';

import './_drawer.scss';
import { IPlacement } from 'rc-drawer/lib/IDrawerPropTypes';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  open?: boolean;
  placement?: IPlacement;
  width?: string | number;
  height?: string | number;
  onClose?: () => void;
}

const Drawer1: React.FC<IProps> = ({
  /* className = '',*/ children,
  open,
  width,
  height,
  placement = 'right',
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  const handleOnClose = () => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleOnClose}
        handler={false}
        level={null}
        width={width}
        height={height}
        placement={placement}
      >
        {children}
      </Drawer>
    </>
  );
};

export default Drawer1;
