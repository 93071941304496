import gql from 'graphql-tag';

export const GET_PRODUCT = gql`
  query getProduct($id: ID!, $accountId: String!) {
    getProduct(id: $id, accountId: $accountId) {
      id
      name
      brand
      description
      gtin
      accountId
      isPublic
      owner {
        id
        username
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    getMe {
      id
      username
      displayName
      avatar
      timezone
      locale
    }
  }
`;

export const LIST_LISTS = gql`
  query listLists($limit: Int, $nextToken: String) {
    listLists(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        itemCount
      }
      nextToken
    }
  }
`;

export const GET_LIST_WITH_ITEMS = gql`
  query getListWithItems($id: ID!, $nextToken: String) {
    getListWithItems(id: $id, nextToken: $nextToken) {
      list {
        id
        name
        description
        itemCount
      }
      items {
        id
        name
        quantity
        description
        gtin
        brand
        defaultImageId
        price
        isApproxPrice
        currency
        note
        productId
        # orderId
        # orderItemId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_PRODUCT_RECOMMENDATIONS = gql`
  query getProductRecommendations {
    getProductRecommendations {
      accountId
      id
      name
      description
      gtin
      defaultImageId
      brand
    }
  }
`;

export const GET_LIST_ITEM = gql`
  query getListItem($itemId: ID!, $listId: String!) {
    getListItem(itemId: $itemId, listId: $listId) {
      id
      name
      quantity
      description
      gtin
      brand
      defaultImageId
      price
      isApproxPrice
      currency
      note
      productId
    }
  }
`;

export const SEARCH = gql`
  query search($term: String!) {
    search(term: $term) {
      items {
        accountId
        id
        name
        description
        gtin
        brand
        defaultImageId
      }
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query listProducts($filter: TableProductFilterInput, $limit: Int, $nextToken: String) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        id
        name
        description
        gtin
        defaultImageId
        brand
        owner {
          id
          username
        }
      }
      nextToken
    }
  }
`;

export const FIND_PRODUCTS_BY_GTIN = gql`
  query findProductsByGtin($gtin: String!) {
    findProductsByGtin(gtin: $gtin) {
      items {
        accountId
        id
        name
        description
        gtin
        defaultImageId
        brand
      }
      nextToken
    }
  }
`;

export const LIST_ORDERS = gql`
  query listOrders($limit: Int, $nextToken: String) {
    listOrders(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        sharingMode
        itemCount
      }
      nextToken
    }
  }
`;

export const GET_ORDER_WITH_ITEMS = gql`
  query getOrderWithItems($id: ID!, $nextToken: String) {
    getOrderWithItems(id: $id, nextToken: $nextToken) {
      order {
        id
        name
        description
        status
        sharingMode
        itemCount
      }
      items {
        id
        name
        quantity
        description
        gtin
        brand
        defaultImageId
        status
        price
        isApproxPrice
        currency
        note
        productId
        pickedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_ORDER_ITEM = gql`
  query getOrderItem($itemId: ID!, $orderId: String!) {
    getOrderItem(itemId: $itemId, orderId: $orderId) {
      id
      name
      quantity
      description
      gtin
      brand
      defaultImageId
      status
      price
      isApproxPrice
      currency
      note
      productId
    }
  }
`;

export const LIST_STORES = gql`
  query listStores($limit: Int, $nextToken: String) {
    listStores(limit: $limit, nextToken: $nextToken) {
      items {
        accountId
        id
        name
        description
        location {
          latitude
          longitude
        }
        verified
      }
      nextToken
    }
  }
`;
