import React from 'react';

import { ProductOption as ProductOptionModel } from '../../../../state/models/productOption.model';
import { ProductOption } from '../ProductOption';

import styles from './ProductOptionList.module.scss';

interface IProps {
  options: ProductOptionModel[];
  selectedOptions?: ProductOptionModel[];
  onChooseOption?: (option: ProductOptionModel, checked: boolean) => void;
}

const ProductOptionList: React.FC<IProps> = ({
  options,
  selectedOptions = undefined,
  onChooseOption = undefined,
}: IProps) => {
  return (
    <>
      <div className={styles.container}>
        {options.length === 0 && <div>Aktuell sind keine Optionen vorhanden</div>}
        {options.length > 0 &&
          options.map((option) => {
            const isChecked = selectedOptions?.find((selectedOption) => {
              return option.id === selectedOption.id;
            })
              ? true
              : false;

            return (
              <div key={option.id}>
                <ProductOption
                  option={{ ...option, value: isChecked }}
                  onChooseOption={onChooseOption}
                  forceSigned={true}
                />
                {/* <hr /> */}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ProductOptionList;
