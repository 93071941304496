import React, { Suspense, useEffect, useState } from 'react';
import { Router, globalHistory, Redirect, navigate } from '@reach/router';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { ApolloProvider } from '@apollo/react-hooks';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { FlagsProvider } from 'flagged';

import { useStateValue } from '../../state';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { NotFoundPage } from '../../modules/common/pages/NotFoundPage';
import { Layout, Spinner } from '..';
import { StoreHomePage } from '../../modules/store/pages';
import { useApolloClient } from '../../hooks/useApolloClient.hook';
import { GET_ME } from '../../graphql/queries';

import './App.scss';

const client = useApolloClient();

const CommonModule = React.lazy(async () => {
  return import('../../modules/common');
});

const BoardModule = React.lazy(async () => {
  return import('../../modules/board');
});

const OrderModule = React.lazy(async () => {
  return import('../../modules/order');
});

const StoreModule = React.lazy(async () => {
  return import('../../modules/store');
});

const ProductModule = React.lazy(async () => {
  return import('../../modules/product');
});

const App: React.FC = () => {
  const { t } = useTranslation(['common']);
  const [{ pwa }, dispatch] = useStateValue();
  const [isOnline, setOnlineStatus] = useState(true);

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      dispatch({ type: 'APP_LOCATION_CHANGE', payload: { location } });
      window.scrollTo(0, 0);
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }, []);

  useEffect(() => {
    const setFromEvent = function (event: { type: string }) {
      if (event.type === 'online') {
        setOnlineStatus(true);
      } else if (event.type === 'offline') {
        setOnlineStatus(false);
      }
    };

    window.addEventListener('online', setFromEvent);
    window.addEventListener('offline', setFromEvent);

    return () => {
      window.removeEventListener('online', setFromEvent);
      window.removeEventListener('offline', setFromEvent);
    };
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (data) => {
        const { data: response } = await client.query({
          query: GET_ME,
        });

        data = {
          ...data,
          displayName: response.getMe.displayName,
          username: response.getMe.username,
        };

        const isAnonymous = !!response.getMe.id?.startsWith('anonymous:');

        if (isAnonymous) {
          Auth.signOut();
        } else {
          dispatch({ type: 'USER_LOGIN', payload: { data } });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn': {
          const { data: response } = await client.query({
            query: GET_ME,
          });

          data = {
            ...data,
            displayName: response.getMe.displayName,
            username: response.getMe.username,
          };

          dispatch({ type: 'USER_LOGIN', payload: { data } });
          navigate('/');
          break;
        }
        case 'signOut':
          dispatch({ type: 'USER_LOGOUT' });
          navigate('/');
          break;
      }
    });
  }, []);

  const updateServiceWorker = () => {
    const registrationWaiting = pwa.serviceWorkerRegistration?.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if ((e.target as ServiceWorker).state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  serviceWorkerRegistration.register({
    onSuccess: () => {
      dispatch({ type: 'SW_INIT' });
    },
    onUpdate: (reg) => {
      dispatch({ type: 'SW_UPDATE', payload: { reg } });
    },
  });

  return (
    <>
      <FlagsProvider /* features={{ e.g. 178130432: true }} */>
        {pwa.serviceWorkerUpdated && (
          <div className="pwa__update--wrapper">
            {t(`common:pwa.update`)}{' '}
            <button className="button tiny" onClick={() => updateServiceWorker()}>
              {t(`common:pwa.updateNow`)}
            </button>
          </div>
        )}
        {!isOnline && <div className="pwa__update--wrapper">{t(`common:pwa.offline`)}</div>}
        <ApolloProvider client={client}>
          <Layout>
            <Suspense fallback={<Spinner />}>
              <Router>
                {/* <OrderOverviewPage path="order" /> */}
                <Redirect from="/order" to="/boards/my" noThrow />
                <Redirect from="/share" to="/boards/my" noThrow />
                <Redirect from="/fulfillment" to="/boards/my" noThrow />
                {/* <Redirect from="/" to="/boards/my" noThrow /> */}
                {/* <OrderFulfillmentPage path="fulfillment" /> */}
                {/* <OrderSharePage path="share" /> */}
                <CommonModule path="/*" />
                <BoardModule path="boards/*" />
                <StoreHomePage path="stores" />
                <OrderModule path="orders/*" />
                <StoreModule path="stores/:storeId/*" />
                <ProductModule path="products/*" />
                <NotFoundPage path="not-found" default />
              </Router>
            </Suspense>
          </Layout>
        </ApolloProvider>
      </FlagsProvider>
    </>
  );
};

export default App;
