import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { readStoreById } from '../../../../services/store.service';
import { useStateValue } from '../../../../state';

import styles from '../../Store.module.scss';

interface IProps extends RouteComponentProps {
  storeId?: string;
}

const StoreSuccessPage: React.FC<IProps> = ({ storeId = undefined }) => {
  const [{ order }, dispatch] = useStateValue();
  if (!storeId) {
    return <></>;
  }

  const store = readStoreById(storeId);

  if (store === undefined) {
    return <></>;
  }

  if (order.lineItemTotal > 0) {
    dispatch({ type: 'CLEAR_CART' });
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1>Vielen Dank für Ihre Bestellung!</h1>
        </div>
        <div className={styles.container}>
          <div>
            Wir haben Ihnen eine Bestätigung an Ihre E-Mail Adresse geschickt.
            <br />
            <br />
            Wir werden Ihre Vorbestellung nun bearbeiten.
            <br />
            <br />
            <h2>Bestellnummer: 4711</h2>
          </div>
          <div>
            <button
              className="button hollow small-only-expanded"
              onClick={() => navigate(`/stores/${storeId}`)}
            >
              Zur Startseite
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreSuccessPage;
