import React from 'react';
import Item from '../Item';

import styles from './ItemList.module.scss';
import { OrderLineItem } from '../../state/models/orderLineItem.model';

interface IProps {
  items: OrderLineItem[];
  children?: React.ReactNode;
  onClick?: (id: string) => void;
  onClickDetails?: (id: string) => void;
}

const ItemList: React.FC<IProps> = ({ items, children, onClick, onClickDetails }) => {
  const handleOnClick = (id: string) => {
    if (onClick) {
      onClick(id);
    }
  };

  const handleOnClickDetails = (id: string) => {
    if (onClickDetails) {
      onClickDetails(id);
    }
  };

  return (
    <div className={`${styles.itemList}`}>
      {items.map((item) => (
        <div key={item.id}>
          <Item
            item={item}
            onClick={(id) => handleOnClick(id)}
            onClickDetails={(id) => handleOnClickDetails(id)}
          />
          <hr />
        </div>
      ))}
      {items.length === 0 && <div>{children}</div>}
    </div>
  );
};

export default ItemList;
