import { Pwa } from '../models/pwa.model';
import { Reducer } from 'react';
import { Action } from '../actions';

export const pwaReducer: Reducer<Pwa, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'SW_INIT':
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };

    case 'SW_UPDATE':
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: payload.reg,
      };

    default:
      return state;
  }
};
