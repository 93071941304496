import React from 'react';

interface IProps {
  className?: string;
}

const Placeholder: React.FC<IProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 125" className={className}>
      <path d="M97.88 56.28H27.13a2.8 2.8 0 0 0-2.8 2.78A38.27 38.27 0 0 0 49.67 95l.28.1a38.18 38.18 0 0 0 50.72-36 2.8 2.8 0 0 0-2.79-2.82ZM30 61.84h18v26.38a32.56 32.56 0 0 1-18-26.38Zm32.51 29.83a31.91 31.91 0 0 1-9-1.27V61.84H95a32.65 32.65 0 0 1-32.49 29.83ZM30.13 51.92a2.77 2.77 0 0 0 2.78-2.78 15.77 15.77 0 0 1 23.47-13.79 23.86 23.86 0 0 1 3.77-4.25 21.35 21.35 0 0 0-32.8 18 2.78 2.78 0 0 0 2.78 2.82ZM57.71 51.92a2.78 2.78 0 0 0 2.79-2.78 15.63 15.63 0 0 1 2-7.66 15.73 15.73 0 0 1 1.5 3.67 15.54 15.54 0 0 1 .52 4 2.79 2.79 0 0 0 5.58 0 21 21 0 0 0-2.36-9.68A20.3 20.3 0 0 0 66.23 37a15.79 15.79 0 0 1 25.86 12.14 2.79 2.79 0 0 0 5.57 0 21.37 21.37 0 1 0-42.73 0 2.77 2.77 0 0 0 2.78 2.78Z" />
    </svg>
  );
};

export default Placeholder;
