import React from 'react';

import styles from './Item.module.scss';
import { OrderLineItem } from '../../state/models/orderLineItem.model';
import { Image as PlaceholderImage } from '../../components';

interface IProps {
  item: OrderLineItem;
  onClick?: (id: string) => void;
  onClickDetails?: (id: string) => void;
}

const Item: React.FC<IProps> = ({ item, onClickDetails }) => {
  const { id, title, quantity, isArchived, defaultImageUrl } = item;

  // const onClickHandler = () => {
  //   if (onClick) {
  //     onClick(id);
  //   }
  // };

  const onClickDetailsHandler = () => {
    if (onClickDetails) {
      onClickDetails(id);
    }
  };

  return (
    <div className={`${styles.item}`}>
      {/* <div className={styles.button__wrapper} onClick={onClickHandler}>
        <div className={`${styles.button} ${isArchived && styles.button__checked}`} />
        <svg className={`${styles.checkmark} ${isArchived && styles.checkmark__checked}`}>
          <polyline points="1,5 6,9 14,1" />
        </svg>
      </div> */}
      <PlaceholderImage url={defaultImageUrl} alt={title} />
      <div className={styles.text__wrapper} onClick={onClickDetailsHandler}>
        <div className={`${styles.text} ${isArchived && styles.text__checked}`}>
          {quantity}x {title}
        </div>
      </div>
      {/* <input
        type="checkbox"
        id={`item-${id}`}
        name={`item-${id}`}
        checked={isArchived}
        onChange={onClickHandler}
      />
      <label htmlFor={`item-${id}`} className={`${styles.button}`} />
      <svg className={`${styles.checkmark}`}>
        <polyline points="1,5 6,9 14,1" />
      </svg>
      {onClickDetails ? (
        <label className={`${styles.text}`} onClick={onClickDetailsHandler}>
          {quantity}x {title}
        </label>
      ) : (
        <label htmlFor={`item-${id}`} className={`${styles.text}`}>
          {quantity}x {title}
        </label>
      )} */}
    </div>
  );
};

export default Item;
