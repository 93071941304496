import React from 'react';

import styles from './RadioButton.module.scss';
import RadioButtonModel from '../../../../models/radioButton.model';

interface IProps {
  item: RadioButtonModel;
  isSelected?: boolean;
  onClick?: (item: RadioButtonModel) => void;
}

const RadioButton: React.FC<IProps> = ({ item, isSelected = false, onClick }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <>
      <div
        className={`${styles.container} ${isSelected && styles.container__selected}`}
        onClick={handleOnClick}
      >
        <div className={styles.title}>{item.title}</div>
        <div className={styles.description}>{item.description}</div>
      </div>
    </>
  );
};

export default RadioButton;
