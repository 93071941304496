import React /*, { useEffect, useState }*/ from 'react';
import { RouteComponentProps } from '@reach/router';
import { usePosition } from 'use-position';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import { Map as LeafletMap } from 'leaflet';
import getDistance from 'geolib/es/getDistance';

import { Spinner } from '../../../../components';

// import styles from '../../Store.module.scss';
// import 'leaflet/dist/leaflet.css';
import StoreList from '../../components/StoreList';
import StoreModel from '../../models/store.model';
import { useQuery } from '@apollo/react-hooks';
import { LIST_STORES } from '../../../../graphql/queries';

const calculateDistance = (srcLocation: any, dstLocation: any): number => {
  const result = getDistance(srcLocation, dstLocation) / 1000;
  return result;
};

const mockupStores: StoreModel[] = [
  { id: '2658df04-e7af-414d-a3db-6a50ea8899c5', name: 'Foodtruck Mustermann' },
  {
    id: '24d6d784-8a3b-40a5-9cc8-544cb8e747b6',
    name: 'Bäckerei Mustermann',
  },
  { id: '7f74df86-56bc-4853-820f-bff958cfdb98', name: 'Metzgerei Mustermann' },
  { id: 'ec16896c-88d2-4819-80da-38e169371799', name: 'Gemüsehof Mustermann' },
  { id: 'a3d5e2a1-5145-4fd7-a1cd-32f7aeff45be', name: 'Imbiss Mustermann' },
];

interface IView {
  stores: StoreModel[];
  latitude?: number;
  longitude?: number;
}

const View: React.FC<IView> = ({ stores }) => {
  // const [map, setMap] = useState<LeafletMap | null>(null);

  // useEffect(() => {
  //   if (map && latitude && longitude) {
  //     map.flyTo({ lat: latitude, lng: longitude }, map.getZoom());
  //   }
  // }, [latitude, longitude]);

  return (
    <>
      <h1>Stores</h1>
      <StoreList stores={stores} />
      {/* <div className={styles.map__container}>
        <MapContainer
          center={[latitude ? latitude : 50.9412818, longitude ? longitude : 6.9560927]}
          zoom={18}
          scrollWheelZoom={false}
          className={styles.map}
          whenCreated={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.505, -0.09]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div> */}
    </>
  );
};

const StoreHomePage: React.FC<RouteComponentProps> = () => {
  const watch = false;
  const { latitude, longitude /*, timestamp, accuracy, errorMessage */ } = usePosition(watch);

  // return (
  //   <div className={styles.location}>
  //     <code>
  //       latitude: {latitude}
  //       <br />
  //       longitude: {longitude}
  //       <br />
  //       timestamp: {timestamp}
  //       <br />
  //       accuracy: {accuracy && `${accuracy}m`}
  //       <br />
  //       error: {errorMessage}
  //     </code>
  //   </div>
  // );

  const { loading, error, data } = useQuery(LIST_STORES);

  if (loading) {
    return <Spinner inverse={true} />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const stores: StoreModel[] = data.listStores.items.map(
    (store: any): StoreModel => {
      if (store) {
        let distance;

        if (store.location && latitude && longitude) {
          distance = calculateDistance({ latitude, longitude }, store.location);
        }

        return {
          id: store.id,
          name: store.name,
          description: store.description,
          location: store.location,
          verified: store.verified,
          distance,
        };
      }
      return {
        id: '0',
        name: '',
      };
    }
  );

  const combinedStores = stores.concat(mockupStores);

  return <View stores={combinedStores} latitude={latitude} longitude={longitude} />;
};

export default StoreHomePage;
