import { Store } from '../state/models/store.model';

const stores: Store[] = [
  {
    id: '24d6d784-8a3b-40a5-9cc8-544cb8e747b6',
    accountId: '',
    name: 'Bäckerei Mustermann',
    heroImage: 'https://static.ordist.com/images/demo/baeckerei-mustermann/heros/hero.jpg',
    searchPlaceholder: 'Suche z.B. Brötchen oder Kuchen',
    products: [
      {
        id: '1',
        accountId: '1',
        name: 'Brötchen',
        price: 0.38,
        sku: '1',
        defaultImageUrl: 'https://static.ordist.com/images/demo/vollkornbroetchen.png',
        synonyms: ['Semmel'],
      },
      {
        id: '2',
        accountId: '1',
        name: 'Mohnbrötchen',
        price: 0.55,
        sku: '2',
      },
      {
        id: '3',
        accountId: '1',
        name: 'Sesambrötchen',
        price: 0.55,
        sku: '3',
      },
      {
        id: '4',
        accountId: '1',
        name: 'Roggenbrötchen',
        price: 0.7,
        sku: '4',
      },
      {
        id: '5',
        accountId: '1',
        name: 'Vollkornbrötchen',
        price: 0.7,
        sku: '5',
      },
      {
        id: '6',
        accountId: '1',
        name: 'Kürbiskernbrötchen',
        price: 0.85,
        sku: '7',
      },
    ],
  },
  {
    id: '7f74df86-56bc-4853-820f-bff958cfdb98',
    accountId: '',
    name: 'Metzgerei Mustermann',
    heroImage: 'https://static.ordist.com/images/demo/metzgerei-mustermann/heros/hero.jpg',
    products: [
      {
        id: '1',
        accountId: '1',
        name: 'Frische Bratwurst',
        sku: '1',
        description: 'vom Eifelschwein',
        price: 1.11,
        priceType: 'GROSS',
        taxRate: 7,
      },
      {
        id: '2',
        accountId: '1',
        name: 'Kalbs-Karree',
        sku: '2',
        description: 'vom deutschen Milchkalb, eine Spezialität aus der Sternegastronomie',
        price: 2.99,
        priceType: 'GROSS',
        taxRate: 7,
      },
      {
        id: '3',
        accountId: '1',
        name: 'Spare-Ribs vom Eifelschwein',
        sku: '3',
        description: 'auf Wunsch auch mariniert, für Grill oder Backofen',
        price: 0.89,
        priceType: 'GROSS',
        taxRate: 7,
      },
      {
        id: '4',
        accountId: '1',
        name: 'Pollo Fino',
        sku: '4',
        description: 'ausgelöste Hänchenoberkeulen, auch mariniert',
        price: 0.89,
        priceType: 'GROSS',
        taxRate: 7,
      },
      {
        id: '5',
        accountId: '1',
        name: 'Schweinefilet-Spiesse',
        sku: '5',
        description: 'mit Speck umwickelt - Musst Du probieren!',
        price: 1.59,
        priceType: 'GROSS',
        taxRate: 7,
      },
      {
        id: '6',
        accountId: '1',
        name: 'Irische Tomahawk-Steaks',
        sku: '6',
        description: 'das Steak für echte Männer, ca. 1000g',
        price: 2.79,
        priceType: 'GROSS',
        taxRate: 7,
      },
      {
        id: '7',
        accountId: '1',
        name: 'Rheinländer-Grillwurst',
        sku: '7',
        description: 'eine hausgemachte Spezialität ohne Zusatzstoffe, nur mit Naturgewürzen',
        price: 3.89,
        priceType: 'GROSS',
        taxRate: 7,
      },
    ],
  },
  {
    id: '2658df04-e7af-414d-a3db-6a50ea8899c5',
    accountId: '',
    name: 'Foodtruck Mustermann',
    heroImage: 'https://static.ordist.com/images/demo/foodtruck-mustermann/heros/hero.jpg',
    products: [
      {
        id: '5',
        accountId: '1',
        name: '180g - Bacon & Egg Burger',
        sku: '5',
        description: '180g Bio-Beef, Cheese, Bacon, Egg',
        price: 9.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '6',
        accountId: '1',
        name: '180g - Bacon & Ziegenkäse Burger',
        sku: '6',
        description: '180g Bio-Beef, Ziegenkäse im Speckmantel mit Honig-Senf-Sauce',
        price: 10.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '4',
        accountId: '1',
        name: '180g - Bacon Cheeseburger',
        sku: '4',
        description: '180g Bio-Beef, Cheese, Bacon',
        price: 9.1,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '8',
        accountId: '1',
        name: '180g - BBQ Bacon Cheeseburger',
        sku: '8',
        description: '180g Bio-Beef, Cheese, extra Bacon, BBQ-Sauce',
        price: 10.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '3',
        accountId: '1',
        name: '180g - Cheeseburger',
        sku: '3',
        description: '180g Bio-Beef, Cheese',
        price: 8.3,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '7',
        accountId: '1',
        name: '180g - Chili Cheese Burger',
        sku: '7',
        description: '180g Bio-Beef, Cheese, Jalapenos, Cheddarsauce',
        price: 10.2,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '76',
        accountId: '1',
        name: '180g - Feiner Mailänder',
        sku: '76',
        description:
          '180g Bio-Beef, Gorgonzola, Feldsalat, Malzbier-Zwiebeln, Pflaumenmus-Senf-Mayo.',
        price: 11.0,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '1',
        accountId: '1',
        name: '180g - Hamburger',
        sku: '1',
        description: '180g Bio-Beef',
        price: 7.6,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '73',
        accountId: '1',
        name: '180g - Schweizer',
        sku: '73',
        description: '180g Bio-Beef, Rösti, Bacon, Cheddarsauce, Röstzwiebeln',
        price: 10.9,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '64',
        accountId: '1',
        name: '180g - Standort-Burger',
        sku: '64',
        description: '180g Bio-Beef, Cheese, Tortilla-Chips, BBQ-Sauce',
        price: 8.9,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '9',
        accountId: '1',
        name: '180g- Big Kahuna Burger',
        sku: '9',
        description: '180g Bio-Beef, Cheese, Bacon, gegrillte Ananas, Teriyaki-Mayo',
        price: 9.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '2',
            name: 'Extra-Pattie',
            description: '',
            price: 4.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '78',
        accountId: '1',
        name: '180g-Charlie Brown',
        sku: '78',
        description: '180g Bio-Beef, Peanutcreme, Cheddar-Sauce, Bacon',
        price: 11.0,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [],
      },
      {
        id: '28',
        accountId: '1',
        name: '360g - Double G',
        sku: '28',
        description: '360g Bio-Beef, Double Cheese, xtra Karamell-Zwiebeln',
        price: 13.0,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '3',
          name: '180g Bio-Beef',
        },
        options: [
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '51',
        accountId: '1',
        name: '90g - Bacon & Ziegenkäse Burger',
        sku: '51',
        description: '90g Bio-Beef, Ziegenkäse , Bacon, Honig-Senf-Sauce',
        price: 7.9,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '48',
        accountId: '1',
        name: '90g - Bacon Cheeseburger',
        sku: '48',
        description: '90g Bio-Beef, Cheese, Bacon',
        price: 6.4,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '45',
        accountId: '1',
        name: '90g - Bacon&Egg Burger',
        sku: '45',
        description: '90g Bio-Beef, Cheese, Bacon, Egg',
        price: 6.9,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '52',
        accountId: '1',
        name: '90g - BBQ Bacon Cheeseburger',
        sku: '52',
        description: '90g Bio-Beef, extra Bacon, BBQ-Sauce',
        price: 7.6,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '49',
        accountId: '1',
        name: '90g - Big Kahuna',
        sku: '49',
        description: '90g Bio-Beef, Cheese, Bacon, gegrillte Ananas, Teriyaki-Mayo',
        price: 6.6,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '79',
        accountId: '1',
        name: '90g - Charlie Brown',
        sku: '79',
        description: '90g Bio-Beef, Peanutcreme, Cheddar-Sauce, Bacon',
        price: 8.1,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [],
      },
      {
        id: '47',
        accountId: '1',
        name: '90g - Cheeseburger',
        sku: '47',
        description: '90g Bio-Beef, Cheese',
        price: 5.8,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '50',
        accountId: '1',
        name: '90g - Chili Cheese Burger',
        sku: '50',
        description: '90g Bio-Beef, Cheese, Jalapenos, Chili-Cheddar-Sauce',
        price: 7.2,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '77',
        accountId: '1',
        name: '90g - Feiner Mailänder',
        sku: '77',
        description:
          '90g Bio-Beef, Gorgonzola, Feldsalat, Malzbier-Zwiebeln, Pflaumenmus-Senf-Mayo.',
        price: 7.8,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '46',
        accountId: '1',
        name: '90g - Hamburger',
        sku: '46',
        description: '90g Bio-Beef',
        price: 5.4,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '74',
        accountId: '1',
        name: '90g - Schweizer',
        sku: '74',
        description: '90g Bio-Beef, Rösti, Bacon, Cheddarsauce, Röstzwiebeln',
        price: 8.2,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '14',
            name: 'Ohne Bacon',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '65',
        accountId: '1',
        name: '90g - Standort-Burger',
        sku: '65',
        description: '90g Bio-Beef, Cheese, Tortilla-Chips, BBQ-Sauce',
        price: 6.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '12',
          name: '90g Bio-Beef',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '15',
            name: 'DURCH',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '75',
        accountId: '1',
        name: "Dinkelmann's Falafel-Burger",
        sku: '75',
        description: 'Mit orientalischer Joghurt-Minz-Sauce',
        price: 7.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '6',
          name: 'Veggie-Burger',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '66',
        accountId: '1',
        name: 'Veggie',
        sku: '66',
        description: 'Veggie-Pattie (gegrilltes Gemüse, Ei), Cheese',
        price: 7.0,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '6',
          name: 'Veggie-Burger',
        },
        options: [
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '10',
        accountId: '1',
        name: 'Pulled Pork Burger',
        sku: '10',
        description: 'Bio-Pulled Pork, Cheese, BBQ-Sauce, Krautsalat',
        price: 9.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '4',
          name: 'Bio-PulledPork',
        },
        options: [
          {
            id: '20',
            name: 'Ohne Sauce',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '11',
            name: 'Ohne Salat',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '10',
            name: 'Ohne Gurke',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '9',
            name: 'Ohne Tomate',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '19',
            name: 'Ohne Bun',
            description: '',
            price: -2.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '8',
            name: 'Extra Ziegenkäse',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '7',
            name: 'Extra Ei',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '4',
            name: 'Extra Bacon',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '1',
            name: 'Extra-Käse',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '69',
        accountId: '1',
        name: 'Pulled Pork Fries',
        sku: '69',
        description: 'knusprige Fritten, Bio-Pulled-Pork, BBQ-Marinade, Cheese',
        price: 7.2,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '4',
          name: 'Bio-PulledPork',
        },
        options: [],
      },
      {
        id: '26',
        accountId: '1',
        name: 'Honey Chicken Fries',
        sku: '26',
        description:
          'Knackige Fries, Pulled-Bio-Chicken,  Cheese, in Teriyaki-Honig-Marinade, Sesam, Lauchzwiebeln',
        price: 7.2,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '5',
          name: 'Bio-PulledChicken',
        },
        options: [
          {
            id: '13',
            name: 'Ohne Käse',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '12',
            name: 'Ohne Zwiebeln',
            description: '',
            price: 0.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '16',
            name: 'Extra Zwiebeln',
            description: '',
            price: 0.3,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '80',
        accountId: '1',
        name: 'Hot Chicken Burger',
        sku: '80',
        description: 'Bio-Pulled-Chicken in feiner Marinade, mit Jalapenos und Chili-Mayo-',
        price: 10.9,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '5',
          name: 'Bio-PulledChicken',
        },
        options: [],
      },
      {
        id: '81',
        accountId: '1',
        name: 'Bärlauch-Parmesan-Fries',
        sku: '81',
        description: 'Knusprige Fritten, Bärlauch-Pesto, Parmesan-Käse',
        price: 6.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '7',
          name: 'Fritten',
        },
        options: [],
      },
      {
        id: '42',
        accountId: '1',
        name: 'Chili Cheese Fries',
        sku: '42',
        description: 'Jalapenos, Chili-Cheddar-Sauce',
        price: 5.3,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '7',
          name: 'Fritten',
        },
        options: [
          {
            id: '18',
            name: 'Extra Cheddar-Sauce',
            description: '',
            price: 1.0,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '6',
            name: 'Extra Sauce',
            description: '',
            price: 0.5,
            priceType: 'GROSS',
            taxRate: 7,
          },
          {
            id: '5',
            name: 'Extra Jalapenos',
            description: '',
            price: 0.6,
            priceType: 'GROSS',
            taxRate: 7,
          },
        ],
      },
      {
        id: '29',
        accountId: '1',
        name: 'Fritten',
        sku: '29',
        description: '',
        price: 3.0,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '7',
          name: 'Fritten',
        },
        options: [],
      },
      {
        id: '22',
        accountId: '1',
        name: 'Haus-Mayo',
        sku: '22',
        description: 'Kräuter',
        price: 0.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '8',
          name: 'Saucen',
        },
        options: [],
      },
      {
        id: '20',
        accountId: '1',
        name: 'Ketchup',
        sku: '20',
        description: '',
        price: 0.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '8',
          name: 'Saucen',
        },
        options: [],
      },
      {
        id: '21',
        accountId: '1',
        name: 'Mayo Classic',
        sku: '21',
        description: '',
        price: 0.5,
        priceType: 'GROSS',
        taxRate: 7,
        category: {
          id: '8',
          name: 'Saucen',
        },
        options: [],
      },
      {
        id: '13',
        accountId: '1',
        name: 'Cola',
        sku: '13',
        description: '',
        price: 2.3,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '14',
        accountId: '1',
        name: 'Cola light',
        sku: '14',
        description: '',
        price: 2.3,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '15',
        accountId: '1',
        name: 'Cola zero',
        sku: '15',
        description: '',
        price: 2.3,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '39',
        accountId: '1',
        name: 'Fritz Apfel-Kirsch-Holunder',
        sku: '39',
        description: '',
        price: 2.6,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '34',
        accountId: '1',
        name: 'Fritz Cola',
        sku: '34',
        description: '',
        price: 2.6,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '35',
        accountId: '1',
        name: 'Fritz Cola light',
        sku: '35',
        description: '',
        price: 2.6,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '38',
        accountId: '1',
        name: 'Fritz Honigmelone',
        sku: '38',
        description: '',
        price: 2.6,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '36',
        accountId: '1',
        name: 'Fritz Orange',
        sku: '36',
        description: '',
        price: 2.6,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '16',
        accountId: '1',
        name: 'Proviant Apfelschorle',
        sku: '16',
        description: '',
        price: 2.5,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '17',
        accountId: '1',
        name: 'Proviant Rhabarberschorle',
        sku: '17',
        description: '',
        price: 2.5,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
      {
        id: '19',
        accountId: '1',
        name: 'Proviant Zitronenlimonade',
        sku: '19',
        description: '',
        price: 2.5,
        priceType: 'GROSS',
        taxRate: 19,
        category: {
          id: '2',
          name: 'Getränke',
        },
        options: [],
      },
    ],
  },
  {
    id: 'ec16896c-88d2-4819-80da-38e169371799',
    accountId: '',
    name: 'Gemüsehof Mustermann',
    heroImage: 'https://static.ordist.com/images/demo/gemuesehof-mustermann/heros/hero.jpg',
    products: [
      {
        id: '1',
        accountId: '1',
        name: 'Salatgurke',
        price: 0.35,
        sku: '1',
        category: {
          id: '1',
          name: 'Gemüse',
          description: '',
        },
      },
      {
        id: '2',
        accountId: '1',
        name: 'Kopfsalat',
        price: 0.79,
        sku: '2',
        category: {
          id: '1',
          name: 'Gemüse',
          description: '',
        },
      },
      {
        id: '3',
        accountId: '1',
        name: 'Banane',
        price: 0.29,
        sku: '3',
        category: {
          id: '2',
          name: 'Obst',
          description: '',
        },
      },
      {
        id: '4',
        accountId: '1',
        name: 'Apfel',
        price: 0.49,
        sku: '4',
        category: {
          id: '2',
          name: 'Obst',
          description: '',
        },
        synonyms: ['Äpfel'],
      },
      {
        id: '5',
        accountId: '1',
        name: 'Wirsing Regional',
        price: 2.5,
        sku: '5',
        category: {
          id: '1',
          name: 'Gemüse',
          description: '',
        },
      },
    ],
  },
  {
    id: 'a3d5e2a1-5145-4fd7-a1cd-32f7aeff45be',
    accountId: '',
    name: 'Imbiss Mustermann',
    heroImage: 'https://static.ordist.com/images/demo/imbiss-mustermann/heros/hero.jpg',
    products: [
      {
        id: '1',
        accountId: '1',
        name: 'Cevapi, klein',
        price: 4.5,
        sku: '1',
      },
      {
        id: '2',
        accountId: '1',
        name: 'Cevapi, groß',
        price: 7.5,
        sku: '2',
      },
      {
        id: '3',
        accountId: '1',
        name: 'Döner',
        price: 4.5,
        sku: '3',
      },
      {
        id: '4',
        accountId: '1',
        name: 'Vegetarischer Döner',
        price: 3.5,
        sku: '4',
      },
    ],
  },
  {
    id: '11',
    accountId: '',
    name: 'Bäckerei Berelsmann',
    // website: 'https://www.berelsmann.net',
    products: [
      {
        id: '1',
        accountId: '1',
        name: 'Brötchen, normal',
        price: 0.45,
        sku: '1',
      },
      {
        id: '2',
        accountId: '1',
        name: 'Mohnbrötchen',
        price: 0.6,
        sku: '2',
      },
    ],
  },
  {
    id: '19',
    accountId: '',
    name: 'Metzgerei Scharrenbroich',
  },
];

export const readStoreById = (storeId: string): Store | undefined => {
  return stores.find((store) => {
    return store.id === storeId;
  });
};
