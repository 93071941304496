import React, { ReactNode, useEffect, useState } from 'react';

import { Button } from '../Button';
import Delete from '../icons/Delete';
import { SubmitButton } from '../SubmitButton';

import styles from './DeleteDialog.module.scss';

export interface IDeleteDialog {
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
  onDelete?: () => Promise<void>;
  onCancel?: () => void;
}

export const DeleteDialog: React.FC<IDeleteDialog> = ({
  isLoading: defaultIsLoading = false,
  children,
  className = '',
  onDelete,
  onCancel,
}) => {
  const [isLoading, setIsLoading] = useState(defaultIsLoading);

  useEffect(() => {
    setIsLoading(defaultIsLoading);
  }, [defaultIsLoading]);

  const handleDelete = async () => {
    if (onDelete) {
      setIsLoading(true);
      await onDelete();
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <Delete className={styles.icon} />
      {children}
      <div className={styles.buttons}>
        <SubmitButton
          isCTA={true}
          isLoading={isLoading}
          className={styles.buttons__delete}
          onClick={handleDelete}
          isDisabled={isLoading}
        >
          Delete
        </SubmitButton>
        <Button className={styles.buttons__cancel} onClick={handleCancel} isDisabled={isLoading}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteDialog;
