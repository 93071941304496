import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';

// import { useStateValue } from '../../../../state';
import { Headline, Profile, Spinner } from '../../../../components';

import styles from '../../Common.module.scss';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME } from '../../../../graphql/queries';
import { useStateValue } from '../../../../state';
import LanguageSelect from '../../../../components/LanguageSelect';

interface IView {
  displayName?: string;
  isSignedIn?: boolean;
  avatar?: string;
}

const View: React.FC<IView> = ({ displayName = '', isSignedIn = false, avatar = '' }) => {
  return (
    <>
      <Helmet>
        <title>User Profile | ordist.com</title>
      </Helmet>
      <Headline className={styles['mt-2']}>User Profile</Headline>
      <div className={`${styles.container} ${styles['mt-4']}`}>
        <Profile avatar={avatar} displayName={displayName} />
        {!isSignedIn && (
          <>
            <div>
              <div>You&apos;re currently in Guest mode. Please login to access all features.</div>
              <Link to="/login" className="button primary small small-only-expanded">
                Sign in
              </Link>
            </div>
          </>
        )}
        <div className={`${styles['mt-4']}`}>
          <LanguageSelect />
        </div>
      </div>
    </>
  );
};

const ProfilePage: React.FC<RouteComponentProps> = () => {
  const [{ user: userState } /*, dispatch*/] = useStateValue();
  const { loading, error, data } = useQuery(GET_ME);

  if (loading) {
    return <Spinner inverse={true} />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const user = data.getMe;

  // dispatch({ type: 'USER_PROFILE_UPDATE', payload: { displayName: user.displayName } });

  return <View displayName={user.displayName} isSignedIn={userState.isSignedIn} />;
};

export default ProfilePage;
