import React, { useState } from 'react';
// import image from '../../../../assets/examples/images/products/5900130030678_80x80.png';

import styles from './Product.module.scss';
import { Product } from '../../../../state/models/product.model';

interface IProps {
  product: Product;
  onAddToCart?: (product: Product, quantity: number) => void;
}

const ProductItem: React.FC<IProps> = ({ product, onAddToCart = undefined }: IProps) => {
  const { name, price, defaultImageUrl = undefined } = product;
  const isApproxPrice = false;

  const [isClicked, setIsClicked] = useState(false);
  const quantity = 1;

  const formatPrice = (value: number) => {
    const result = value.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${result} €`;
  };

  const handleAddToCart = () => {
    setIsClicked(true);
    if (onAddToCart) {
      onAddToCart(product, quantity);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container__info_wrapper}>
          <div className={styles.container__description}>
            <h3 className={styles.container__title}>{name}</h3>
            {/* {product.subline && <h3 className={styles.container__subtitle}>{subline}</h3>} */}
          </div>
          {defaultImageUrl && (
            <img className={styles.container__image} src={defaultImageUrl} alt={name} />
          )}
        </div>
        <div className={styles.container__price_wrapper}>
          {/* <div className={styles.container__quantity}>
          <Quantity
            quantity={quantity}
            min={1}
            max={1000}
            removeOnMin={false}
            onChangeQuantity={changeQuantity}
          />
        </div> */}
          <div className={styles.container__price}>
            {price && isApproxPrice && <span>≈</span>}
            {price && <span>{formatPrice(quantity * price)}</span>}
            {/* {price && <span> / Stück</span>} */}
          </div>

          <div
            className={`${styles.container__cart} ${isClicked && styles.animation}`}
            onClick={handleAddToCart}
            onAnimationEnd={() => setIsClicked(false)}
          />
        </div>
      </div>
    </>
  );
};

export default ProductItem;
