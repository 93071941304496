import React from 'react';
import { useTranslation } from 'react-i18next';
import { appConfig } from '../../../../common/config';
import { buildDate } from '../../../../common/util';

import styles from './UserMenu.module.scss';

const dateTimeOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZone: 'Europe/Berlin',
};

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const UserMenu: React.FC<IProps> = ({ className = '', children }) => {
  const { t } = useTranslation(['common', 'footer']);

  const version = appConfig.version;
  return (
    <div className={`${styles.container} ${className}`}>
      <div>{children}</div>
      <div className={`${styles.footer}`}>
        {/* <div className={styles.footer__copyright}>
          © {new Date().getFullYear()} {` `}
          egomerce, all rights reserved.
        </div> */}
        <div className={`${styles.footer__version}`}>
          Version: {version} ({t(`footer:meta.build.lastBuild`)}:{' '}
          {new Intl.DateTimeFormat('de-DE', dateTimeOptions).format(buildDate)})
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
