import React, { useEffect, useState } from 'react';

import styles from './Favorite.module.scss';

export interface IFavorite {
  checked?: boolean;
  className?: string;
  onClick?: (checked: boolean) => void;
}

const Favorite: React.FC<IFavorite> = ({ checked = false, className = '', onClick }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleClick = () => {
    const newStatus = !isChecked;
    setIsChecked(newStatus);

    if (onClick) {
      onClick(newStatus);
    }
  };

  return (
    <div className={`${styles.container} ${className}`} onClick={handleClick}>
      {isChecked ? (
        <svg
          aria-hidden="true"
          className={`${styles.favorite} ${styles.favorite__checked}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 18"
        >
          <path d="M19.2 2C18.1.9 16.7.3 15.2.2c-1.5-.1-3 .3-4.2 1.2C9.7.4 8.1 0 6.6.2c-1.6.1-3.1.9-4.1 2.1S.9 5.1 1 6.6c.1 1.6.7 3.1 1.8 4.2L9 17c.5.5 1.2.8 2 .8s1.4-.3 1.9-.8l6.2-6.2C20.3 9.7 21 8.1 21 6.4c0-1.6-.7-3.2-1.8-4.4z" />
        </svg>
      ) : (
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 18"
          className={`${styles.favorite} ${styles.favorite__unchecked}`}
        >
          <path d="M19.16 2A6.29 6.29 0 0 0 11 1.36a6.27 6.27 0 0 0-8.16 9.48l6.21 6.22a2.78 2.78 0 0 0 3.9 0l6.21-6.22a6.27 6.27 0 0 0 0-8.84Zm-1.41 7.46-6.21 6.21a.76.76 0 0 1-1.08 0L4.25 9.43a4.29 4.29 0 0 1 0-6 4.27 4.27 0 0 1 6 0 .999.999 0 0 0 1.42 0 4.27 4.27 0 0 1 6 0 4.29 4.29 0 0 1 .08 6v.03Z" />
        </svg>
      )}
    </div>
  );
};

export default Favorite;
