import React from 'react';

import styles from './Label.module.scss';

export interface ILabel {
  value?: string;
  htmlFor?: string;
  children?: React.ReactNode;
  className?: string;
}

export const Label: React.FC<ILabel> = ({ value, htmlFor, children, className = '' }) => {
  return (
    <label htmlFor={htmlFor} className={`${styles.label} ${className}`}>
      {value}
      {children}
    </label>
  );
};

export default Label;
