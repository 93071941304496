import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import Close from '../icons/Close';
import { useScrollLock } from '../../hooks/useScrollLock.hook';
import { useClickOutside } from '../../hooks/useClickOutside.hook';

import styles from './Modal.module.scss';

interface IProps {
  children: React.ReactNode;
  title?: string;
  show: boolean;
  onClose?: () => void;
}

const Modal: React.FC<IProps> = ({ title = '', show, children, onClose }) => {
  useScrollLock(show, false);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const modalRef = useRef(null);
  useClickOutside(modalRef, handleClose);

  return (
    <CSSTransition
      in={show}
      timeout={200}
      classNames={{
        enter: styles['fade--enter'],
        enterActive: styles['fade--enter-active'],
        exit: styles['fade--exit'],
        exitActive: styles['fade--exit-active'],
      }}
      unmountOnExit={true}
    >
      <div className={styles.container}>
        <CSSTransition
          in={show}
          timeout={200}
          classNames={{
            appear: styles['slide--enter'],
            appearActive: styles['slide--enter-active'],
            exit: styles['slide--exit'],
            exitActive: styles['slide--exit-active'],
          }}
          appear={true}
        >
          <div className={styles.modal} ref={modalRef}>
            <div className={styles.modal__header}>
              <div className={styles.modal__header__headline}>{title}</div>
              <div className={styles.modal__header__close} onClick={handleClose}>
                <Close className={`${styles.icon} ${styles.icon__close}`} />
              </div>
            </div>
            <div className={styles.modal__main}>{children}</div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

export default Modal;
