import React from 'react';
// import { Link } from '@reach/router';

import styles from './BottomNavigationAction.module.scss';

export interface IBottomNavigationAction {
  icon?: React.ReactNode;
  label?: string;
  isSelected?: boolean;
  navigateTo?: string;
  className?: string;
  onClick?: (location: string) => void;
}

export const BottomNavigationAction: React.FC<IBottomNavigationAction> = ({
  icon,
  label,
  isSelected = false,
  navigateTo = '/',
  className = '',
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(navigateTo);
    }
  };

  return (
    <button className={`${styles.container} ${className}`} onClick={handleClick}>
      {/* <Link to={navigateTo}> */}
      {icon && (
        <div className={`${styles.icon} ${isSelected ? styles.icon__active : ''}`}>{icon}</div>
      )}
      {label && (
        <div className={`${styles.label} ${isSelected ? styles.label__active : ''}`}>{label}</div>
      )}
      {/* </Link> */}
    </button>
  );
};

export default BottomNavigationAction;
