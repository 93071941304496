import React from 'react';

interface IProps {
  className?: string;
}

const BadgeCheck: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 18"
      className={className}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.133.968a1.606 1.606 0 0 0-2.266 0l-.51.504a1.61 1.61 0 0 1-1.362.451l-.703-.101c-.87-.126-1.682.476-1.834 1.36l-.123.714a1.662 1.662 0 0 1-.841 1.182l-.628.34a1.68 1.68 0 0 0-.7 2.2l.312.651c.22.461.22 1 0 1.462l-.312.65a1.68 1.68 0 0 0 .7 2.201l.628.34c.444.24.754.677.841 1.182l.123.714c.152.884.963 1.486 1.834 1.36l.703-.102a1.61 1.61 0 0 1 1.361.452l.51.504a1.606 1.606 0 0 0 2.267 0l.51-.504a1.61 1.61 0 0 1 1.362-.451l.703.101c.87.126 1.682-.476 1.834-1.36l.123-.714c.087-.505.397-.941.841-1.182l.628-.34a1.68 1.68 0 0 0 .7-2.2l-.312-.651c-.22-.461-.22-1 0-1.462l.312-.65a1.68 1.68 0 0 0-.7-2.201l-.628-.34a1.662 1.662 0 0 1-.841-1.182l-.123-.714c-.152-.884-.963-1.486-1.834-1.36l-.703.101a1.61 1.61 0 0 1-1.361-.451l-.51-.504ZM4.44 9.916a.986.986 0 0 0 .2.312l1.736 1.862c.085.091.185.164.295.213a.854.854 0 0 0 .697 0c.11-.05.21-.122.294-.212v-.001l3.69-3.958a.974.974 0 0 0 .208-.317 1.033 1.033 0 0 0 0-.78.973.973 0 0 0-.208-.316.904.904 0 0 0-.288-.206.853.853 0 0 0-.697 0 .905.905 0 0 0-.294.213l-3.054 3.28-1.1-1.185-.003-.003a.912.912 0 0 0-.3-.207.861.861 0 0 0-.702.013.918.918 0 0 0-.293.218.993.993 0 0 0-.194.322 1.046 1.046 0 0 0 .013.752Z"
      ></path>
    </svg>
  );
};

export default BadgeCheck;
