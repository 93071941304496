import React, { useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useStateValue } from '../../../../state';

// import styles from '../../Common.module.scss';

const LogoutPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    dispatch({ type: 'USER_LOGOUT' });
    navigate('/');
  }, []);

  return (
    <>
      <h1>{t(`auth:headline`)}</h1>
      <div>You have been successfully logged out.</div>
    </>
  );
};

export default LogoutPage;
