import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignOut,
  AmplifySignUp,
} from '@aws-amplify/ui-react';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// import styles from '../../Common.module.scss';

const LoginPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t(`auth:headline`)}</h1>
      <div>
        <button
          className="button primary small"
          onClick={() =>
            Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
          }
        >
          Facebook Login
        </button>
      </div>
      <div>
        <button
          className="button primary small"
          onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
        >
          Google Login
        </button>
      </div>
      <AmplifyAuthenticator>
        <AmplifySignUp
          headerText="Create a new Ordist account"
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: 'email',
              label: 'Email',
              placeholder: 'Email',
              required: true,
            },
            {
              type: 'password',
              label: 'Password',
              placeholder: 'Password',
              required: true,
            },
          ]}
        />
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
        <AmplifySignOut />
      </AmplifyAuthenticator>
    </>
  );
};

export default LoginPage;
