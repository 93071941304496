import React, { useState } from 'react';

import RadioButtonModel from '../../../../models/radioButton.model';
import RadioButton from '../RadioButton';

import styles from './RadioButtonList.module.scss';

interface IProps {
  items: RadioButtonModel[];
  selectedId?: string;
}

const RadioButtonList: React.FC<IProps> = ({ items, selectedId: initialSelectedId }) => {
  const [selectedId, setSelectedId] = useState<string | undefined>(initialSelectedId);

  const handleOnClick = (item: RadioButtonModel) => {
    setSelectedId(item.id);
  };

  return (
    <div className={styles.container}>
      <ul>
        {items.map((item) => {
          const isSelected = selectedId && selectedId === item.id ? true : false;
          return (
            <li key={item.id}>
              <RadioButton item={item} isSelected={isSelected} onClick={handleOnClick} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RadioButtonList;
