import React from 'react';

import styles from './Headline.module.scss';

export interface IHeadline {
  children?: React.ReactNode;
  className?: string;
}

export const Headline: React.FC<IHeadline> = ({ children, className = '' }) => {
  return <div className={`${styles.headline} ${className}`}>{children}</div>;
};

export default Headline;
