import { navigate } from '@reach/router';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Barcode from '../icons/Barcode';

import styles from './SearchBar.module.scss';

interface IProps {
  value?: string;
  showCancel?: boolean;
  showScan?: boolean;
  onChangeSearchTerm?: (searchTerm: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onCancel?: () => void;
  reset?: boolean;
  showIcon?: boolean;
  translations?: ITranslations | undefined;
}

interface ITranslations {
  placeholder?: string;
}

const SearchBar: React.FC<IProps> = ({
  value,
  showCancel,
  showScan,
  onChangeSearchTerm,
  onFocus,
  onBlur,
  onCancel,
  reset = false,
  showIcon = false,
  translations = undefined,
}) => {
  const { t } = useTranslation(['common']);
  const [searchTerm, setSearchTerm] = useState(value);
  // const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { current } = refInput;

    const handleFocus = () => {
      if (onFocus) {
        onFocus();
      }
    };

    const handleBlur = () => {
      if (onBlur) {
        onBlur();
      }
    };

    if (current) {
      current.addEventListener('focus', handleFocus);
      current.addEventListener('blur', handleBlur);
    }

    return () => {
      if (current) {
        current.removeEventListener('focus', handleFocus);
        current.removeEventListener('blur', handleBlur);
      }
    };
  }, [onFocus, onBlur]);

  if (reset) {
    if (searchTerm !== '') {
      setSearchTerm('');
      if (onCancel) {
        onCancel();
      }
    }
  }

  const mergedTranslations = {
    placeholder: translations?.placeholder
      ? translations?.placeholder
      : t(`common:search.searchBar.placeholder`),
  };

  // const focused = useFocus(ref);

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      // onSearch();
    }
  };

  const handleChangeSearchTerm = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    if (onChangeSearchTerm) {
      onChangeSearchTerm(searchTerm);
    }
    // debounced.callback(searchTerm);
  };

  const handleSearchCancel = () => {
    setSearchTerm('');
    if (onCancel) {
      onCancel();
    }
  };

  const handleScan = () => {
    navigate(`/scanner`);
  };

  return (
    <div className={styles.container}>
      <div className="input-group">
        <input
          type="text"
          className={`input-group-field ${showIcon && styles.icon}`}
          value={searchTerm}
          placeholder={mergedTranslations.placeholder}
          onKeyDown={(e) => handleKeyDown(e.key)}
          onChange={(e) => handleChangeSearchTerm(e.target.value)}
          ref={refInput}
        />
        {showCancel && (
          <div className="input-group-button">
            <button className="button" onClick={handleSearchCancel}>
              Cancel
            </button>
          </div>
        )}
        {showScan && (
          <div className="input-group-button">
            <button className="button" onClick={handleScan}>
              <Barcode className={styles.scan__icon} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
