import React from 'react';
import { RouteComponentProps } from '@reach/router';
// import { useTranslation } from 'react-i18next';

// import { Layout } from '../../../../components';

// import styles from '../../Common.module.scss';
import BarcodeScanner from '../../../../components/BarcodeScanner';

const ScannerPage: React.FC<RouteComponentProps> = () => {
  // const { t } = useTranslation();

  return (
    <div>
      <BarcodeScanner />
    </div>
  );
};

export default ScannerPage;
