import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import Location from '../../components/Location';

import styles from '../../Store.module.scss';
import LocationModel from '../../../../models/location.model';

const locations: LocationModel[] = [
  {
    id: '1',
    title: 'Bielefeld-Senne',
    description: 'Stadtteil Senne, bei Autohaus Mustermann',
    message: 'NUR ZUR ABHOLUNG!',
    address: {
      streetAddress: 'Test Str. 27',
      postalCode: '33659',
      city: 'Bielefeld',
    },
    email: 'info@example.com',
    telephone: '+49 0123 45678 90',
  },
];

interface IProps extends RouteComponentProps {
  storeId?: string;
}

const StoreLocationPage: React.FC<IProps> = ({ storeId = undefined }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1>Ort &amp; Uhrzeit</h1>
        </div>
        {locations && locations.length > 0 && (
          <div className={styles.container}>
            <div>
              Angabe von Datum, Uhrzeit und Ort.
              <Location item={locations[0]} />
            </div>
            <div>
              <button
                className="button small-only-expanded float-right"
                onClick={() => navigate(`/stores/${storeId}/personal-data`)}
              >
                Weiter
              </button>
              <button
                className="button hollow small-only-expanded float-left"
                onClick={() => navigate(`/stores/${storeId}/delivery`)}
              >
                Zurück zur Lieferung
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StoreLocationPage;
