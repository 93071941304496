import React from 'react';

import styles from './Avatar.module.scss';

interface IProps {
  src?: string;
  className?: string;
}

const Avatar: React.FC<IProps> = ({ src = undefined, className = '' }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {src && <img src={src} className={`${styles.avatar} ${className}`} />}
    </div>
  );
};

export default Avatar;
