import React, { useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { readStoreById } from '../../../../services/store.service';
import { OrderItemList } from '../../../order/components';
import { useStateValue } from '../../../../state';

import styles from '../../Store.module.scss';
import { OrderLineItem } from '../../../../state/models/orderLineItem.model';
import { CartModal } from '../../components/CartModal';

interface IProps extends RouteComponentProps {
  storeId?: string;
}

const StoreCartPage: React.FC<IProps> = ({ storeId = undefined }) => {
  const [{ order }, dispatch] = useStateValue();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<OrderLineItem | undefined>();

  if (!storeId) {
    return <></>;
  }

  const store = readStoreById(storeId);

  if (store === undefined) {
    return <></>;
  }

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleChangeQuantity = (id: string, quantity: number) => {
    dispatch({ type: 'SET_QUANTITY', payload: { id, quantity } });
  };

  const handleUpdateCart = (item: OrderLineItem) => {
    dispatch({ type: 'UPDATE_ORDER', payload: { item } });
    closeModal();
  };

  const handleDelete = (id: string) => {
    dispatch({ type: 'DELETE_ORDER', payload: { id } });
  };

  const handleClick = (item: OrderLineItem) => {
    setSelectedItem(item);
    openModal();
  };

  return (
    <>
      <CartModal
        item={selectedItem}
        showModal={showModal}
        onCloseModal={closeModal}
        onUpdateCart={handleUpdateCart}
      />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1>Warenkorb</h1>
        </div>
        <div className={styles.container}>
          <OrderItemList
            items={order.lineItems}
            onChangeQuantity={handleChangeQuantity}
            onDelete={handleDelete}
            onClick={handleClick}
          />
        </div>
        <div>
          <button
            className="button small-only-expanded float-right"
            onClick={() => navigate(`/stores/${storeId}/delivery`)}
            disabled={order.lineItemCount === 0}
          >
            Bestellen
          </button>
          <button
            className="button hollow small-only-expanded float-left"
            onClick={() => navigate(`/stores/${storeId}`)}
          >
            Schließen
          </button>
        </div>
      </div>
    </>
  );
};

export default StoreCartPage;
