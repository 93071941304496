import React, { useState } from 'react';
import Close from '../icons/Close';
import Settings from '../icons/Settings';

import styles from './SettingsToolbar.module.scss';

export interface ISettingsToolbar {
  showSettings?: boolean;
  isVisible?: boolean;
  isEnabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  onToggleStatus?: (showSettings: boolean) => void;
}

export const SettingsToolbar: React.FC<ISettingsToolbar> = ({
  showSettings = false,
  isVisible = true,
  isEnabled = true,
  children,
  className = '',
  onToggleStatus,
}) => {
  const [_showSettings, _setShowSettings] = useState<boolean>(showSettings);

  const handleOnClick = () => {
    const status = !_showSettings;

    _setShowSettings(status);

    if (onToggleStatus) {
      onToggleStatus(status);
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      {children}
      {isVisible && _showSettings && (
        <div className={styles.icon} onClick={handleOnClick}>
          <Close />
        </div>
      )}
      {isVisible && isEnabled && !_showSettings && (
        <div className={`${styles.icon} ${styles.icon__enabled}`} onClick={handleOnClick}>
          <Settings />
        </div>
      )}
      {isVisible && !isEnabled && !_showSettings && (
        <div className={`${styles.icon} ${styles.icon__disabled}`}>
          <Settings />
        </div>
      )}
    </div>
  );
};

export default SettingsToolbar;
