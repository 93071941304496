import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { readStoreById } from '../../../../services/store.service';
import { OrderLineItem } from '../../../../state/models/orderLineItem.model';

import styles from '../../Store.module.scss';

interface IProps extends RouteComponentProps {
  storeId?: string;
}

const StorePersonalDataPage: React.FC<IProps> = ({ storeId = undefined }) => {
  if (!storeId) {
    return <></>;
  }

  const store = readStoreById(storeId);

  if (store === undefined) {
    return <></>;
  }

  const items: OrderLineItem[] = [];

  store.products?.map((product) => {
    items.push({
      id: product.id,
      title: product.name,
      subline: '',
      quantity: 0,
      price: product.price ? product.price : 0.0,
      isApproxPrice: false,
    });
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1>Persönliche Daten</h1>
        </div>
        <div className={styles.container}>
          <div>
            Basierend auf der Liefermethode, werden hier verschiedene persönliche Daten abgefragt.
            <br />
            <br />
            z.B. Name, Telefonnummer, E-Mail und Adresse
          </div>
          <div>
            <p></p>
            <button
              className="button small-only-expanded float-right"
              onClick={() => navigate(`/stores/${storeId}/payment`)}
            >
              Weiter
            </button>
            <button
              className="button hollow small-only-expanded float-left"
              onClick={() => navigate(`/stores/${storeId}/location`)}
            >
              Zurück zur Ort &amp; Zeit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StorePersonalDataPage;
