import React, { useState, useEffect } from 'react';

import { ProductOption as ProductOptionModel } from '../../../../state/models/productOption.model';

import styles from './ProductOption.module.scss';
import { formatPrice } from '../../../../lib/format.extensions';

interface IProps {
  option: ProductOptionModel;
  onChooseOption?: (option: ProductOptionModel, checked: boolean) => void;
  forceSigned?: boolean;
}

const ProductOption: React.FC<IProps> = ({
  option,
  onChooseOption = undefined,
  forceSigned = false,
}: IProps) => {
  const { name, description, price, value = undefined } = option;

  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    if (typeof value === 'boolean') {
      setIsChecked(value);
    }
  }, [value]);

  const handleOnClick = () => {
    const result = !isChecked;
    setIsChecked(result);
    if (onChooseOption) {
      onChooseOption(option, result);
    }
  };

  return (
    <>
      <div className={styles.container} onClick={handleOnClick}>
        <div className={styles.checkbox_wrapper}>
          <span className={`${styles.checkbox} ${isChecked && styles.checkbox_checked}`}></span>
        </div>
        <div className={styles.container__info_wrapper}>
          <div className={styles.container__title}>{name}</div>
          <div className={styles.container__description}>{description}</div>
        </div>
        {price !== undefined && price !== 0 && (
          <div className={styles.money__wrapper}>{formatPrice(price, forceSigned)}</div>
        )}
      </div>
    </>
  );
};

export default ProductOption;
