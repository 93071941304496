import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from '../../../../state/models/product.model';
import { ProductOption as ProductOptionModel } from '../../../../state/models/productOption.model';
import { ProductDetails } from '../ProductDetails';
import { Modal, Quantity } from '../../../../components';
import { formatPrice } from '../../../../lib/format.extensions';

import styles from './CartModal.module.scss';
import { OrderLineItem } from '../../../../state/models/orderLineItem.model';
import _ from 'lodash';

export const getTotal = (product: Product, options: ProductOptionModel[], quantity = 1): number => {
  let total = product.price ? product.price : 0;

  options.forEach((option) => {
    if (option.price) {
      total += option.price;
    }
  });

  return total * quantity;
};

interface IProps {
  item?: OrderLineItem;
  showModal?: boolean;
  onCloseModal?: () => void;
  onUpdateCart?: (item: OrderLineItem) => void;
}

const CartModal: React.FC<IProps> = ({
  item,
  showModal = true,
  onCloseModal = undefined,
  onUpdateCart = undefined,
}) => {
  const product: Product | undefined = item?.product;

  const { t } = useTranslation(['common']);
  const [total, setTotal] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<ProductOptionModel[]>([]);
  const [quantity, setQuantity] = useState(item ? item.quantity : 1);

  useEffect(() => {
    if (product) {
      const total = getTotal(product, selectedOptions, quantity);
      setTotal(total);
    }
  }, [item, product, selectedOptions, quantity]);

  useEffect(() => {
    const reset = () => {
      setQuantity(item ? item.quantity : 1);
      if (item?.options) {
        setSelectedOptions(_.cloneDeep(item.options));
      } else {
        setSelectedOptions([]);
      }
    };

    if (showModal) {
      reset();
    }
  }, [showModal, item]);

  const handleCloseModal = () => {
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const handleUpdateCart = () => {
    if (item && onUpdateCart) {
      // id: `temp_${uuidv4()}`,
      // product: product,
      // sku: product.sku,
      // title: product.name,
      // subline: '',
      // isArchived: false,
      // updatedAt: new Date(),
      // price: product.price ? getTotal(product, options) : undefined,
      // quantity: quantity,
      // isApproxPrice: false,
      // options: options,
      const newItem = {
        ...item,
        options: selectedOptions,
        quantity: quantity,
        price: product?.price ? getTotal(product, selectedOptions) : undefined,
      };
      onUpdateCart(newItem);
    }
  };

  const handleChooseOption = (option: ProductOptionModel, checked: boolean) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, option]);
    }
    if (!checked) {
      setSelectedOptions([...selectedOptions.filter((item) => item.id !== option.id)]);
    }
  };

  const handleChangeQuantity = (quantity: number) => {
    setQuantity(quantity);
  };

  return (
    <>
      <div className={styles.container}>
        <Modal title={t(`store:product.options.edit`)} show={showModal} onClose={handleCloseModal}>
          {product && (
            <div className="grid-container">
              <div className="grid-x margin-x">
                <div className="small-12">
                  <div className={styles.modal__main}>
                    <ProductDetails
                      product={product}
                      selectedOptions={selectedOptions}
                      onChooseOption={handleChooseOption}
                    />
                    <div className={styles.container__quantity}>
                      <div className={styles.modal__quantity}>
                        <Quantity
                          quantity={quantity}
                          min={1}
                          removeOnMin={false}
                          onChangeQuantity={handleChangeQuantity}
                        />
                      </div>
                    </div>
                    <div className={styles.container__total}>
                      {t(`common:cart.total`)}: {formatPrice(total)}
                    </div>
                    <div className={styles.container__buttons}>
                      <button
                        className="button small-only-expanded float-right"
                        onClick={handleUpdateCart}
                      >
                        {t(`store:buttons.update`)}
                      </button>
                      <button
                        className="button hollow small-only-expanded float-left"
                        onClick={handleCloseModal}
                      >
                        {t(`common:buttons.cancel`)}
                      </button>
                    </div>
                  </div>
                  <div className={styles.scrollsafe} />
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default CartModal;
