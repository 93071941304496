import React from 'react';
import Favorite from '../../../../components/Favorite';
import { IFavorite } from '../../../../components/Favorite/Favorite';
import Plus from '../../../../components/icons/Plus';

import { IProduct, Product } from '../Product/Product';

import styles from './ProductListItem.module.scss';

export interface IProductListItem extends IProduct, Omit<IFavorite, 'onClick'> {
  className?: string;
  onAddToList?: () => void;
  onFavoriteClick?: (isChecked: boolean) => void;
}

export const ProductListItem: React.FC<IProductListItem> = ({
  product,
  isVerified = false,
  checked = false,
  className = '',
  onClick,
  onAddToList,
  onFavoriteClick,
}) => {
  const handleAddToList = () => {
    if (onAddToList) {
      onAddToList();
    }
  };

  const handleFavoriteClick = (isChecked: boolean) => {
    if (onFavoriteClick) {
      onFavoriteClick(isChecked);
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <Product product={product} isVerified={isVerified} onClick={onClick} />
      <div className={styles.menu}>
        <li>
          <Favorite checked={checked} onClick={handleFavoriteClick} />
        </li>
        {onAddToList && (
          <li onClick={handleAddToList}>
            <Plus className={styles.icons__plus} />
          </li>
        )}
      </div>
    </div>
  );
};

export default ProductListItem;
