import React from 'react';

import styles from './Hint.module.scss';

export interface IHint {
  children?: React.ReactNode;
  className?: string;
}

export const Hint: React.FC<IHint> = ({ children, className = '' }) => {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
};

export default Hint;
