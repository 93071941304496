import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import { State } from './models';
import { mainReducer } from './reducers';
import { Action } from './actions';

const getInitialState = (): State => ({
  pwa: {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
  },
  order: {
    lineItems: [],
    lineItemCount: 0,
    lineItemTotal: 0,
  },
  list: {
    items: [],
    itemCount: 0,
    itemTotal: 0,
    showItemsAlreadyOnOrder: true,
  },
  store: {
    id: '',
    accountId: '',
    name: '',
  },
  user: {
    isSignedIn: false,
    selectedBoard: undefined,
    selectedOrder: undefined,
    username: undefined,
    displayName: undefined,
    avatar: undefined,
    identity: undefined,
  },
  app: {
    location: '/',
    isDetailMode: false,
  },
});

export const StateContext = createContext<[State, Dispatch<Action>]>([
  getInitialState(),
  () => null,
]);

export const StateProvider: React.FC = ({ children }) => {
  let initialState = getInitialState();

  try {
    const storageName = `list_my`;
    const list = localStorage.getItem(storageName);

    if (list) {
      initialState = { ...initialState, list: JSON.parse(list) };
    }
  } catch (error) {
    console.log(error);
  }

  try {
    const storageName = `user`;
    const user = localStorage.getItem(storageName);

    if (user) {
      initialState = { ...initialState, user: JSON.parse(user) };
    }
  } catch (error) {
    console.log(error);
  }

  return (
    <StateContext.Provider value={useReducer(mainReducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = (): [State, Dispatch<Action>] => useContext(StateContext);
