import React, { useState, useRef, ReactNode } from 'react';
import styles from './LanguageSelect.module.scss';
import { useTranslation } from 'react-i18next';
import enFlag from '../../assets/images/flags/en.svg';
import deFlag from '../../assets/images/flags/de.svg';
import esFlag from '../../assets/images/flags/es.svg';
import itFlag from '../../assets/images/flags/it.svg';
import trFlag from '../../assets/images/flags/tr.svg';
import { TFunction } from 'i18next';
import { useClickOutside } from '../../hooks/useClickOutside.hook';

const getLanguages = (t: TFunction) => [
  // { code: 'tr', label: t(`languages.tr.label`), flag: trFlag },
  // { code: 'es', label: t(`languages.es.label`), flag: esFlag },
  // { code: 'it', label: t(`languages.it.label`), flag: itFlag },
  { code: 'de', label: t(`languages.de.label`), flag: deFlag },
  { code: 'en', label: t(`languages.en.label`), flag: enFlag },
];

const getLanguageCode = (code: string) => {
  if (code.length <= 2) {
    return code;
  }

  return code.substr(0, 2);
};

const getCurrentLanguageCode = (code: string) => {
  switch (getLanguageCode(code)) {
    case 'en':
      return 'en';

    case 'de':
      return 'de';

    case 'es':
      return 'es';

    case 'it':
      return 'it';

    case 'tr':
      return 'tr';

    default:
      return 'en';
  }
};

const LanguageSelect: React.FC = () => {
  const wrapperRef = useRef(null);
  const [showList, setShowList] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleList = () => setShowList(!showList);
  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
    toggleList();
  };

  useClickOutside(wrapperRef, () => setShowList(false));

  const renderFlag = (code: string): ReactNode => {
    switch (getLanguageCode(code)) {
      case 'en':
        return <img src={enFlag} className={styles.flag__image} alt={code} />;

      case 'de':
        return <img src={deFlag} className={styles.flag__image} alt={code} />;

      case 'es':
        return <img src={esFlag} className={styles.flag__image} alt={code} />;

      case 'it':
        return <img src={itFlag} className={styles.flag__image} alt={code} />;

      case 'tr':
        return <img src={trFlag} className={styles.flag__image} alt={code} />;

      default:
        i18n.changeLanguage('en');
        return renderFlag('en');
    }
  };

  return (
    <div className={styles.container} ref={wrapperRef}>
      <div className={styles.flag} onClick={toggleList}>
        <div className={styles.menu} style={{ display: showList ? 'block' : 'none' }}>
          <ul className={styles.menu__list}>
            {getLanguages(t)
              .filter((l) => l.code !== getLanguageCode(i18n.language))
              .map((l, i) => (
                <li
                  key={`lang-${i}`}
                  className={styles.menu__list__item}
                  onClick={() => changeLanguage(l.code)}
                >
                  {renderFlag(l.code)}
                  <span className={styles.menu__label}>{l.label}</span>
                </li>
              ))}
          </ul>
          <div className={styles.menu__arrow} />
        </div>
        {renderFlag(i18n.language)}
        <span className={styles.language__label}>
          {' '}
          {t(`languages.${getCurrentLanguageCode(i18n.language)}.label`)}
        </span>
      </div>
    </div>
  );
};

export default LanguageSelect;
