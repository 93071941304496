import React from 'react';

import { Brand } from '../../../../components/Brand';
import { Image as PlaceholderImage } from '../../../../components';
import ProductModel from '../../models/product.model';

import styles from './Product.module.scss';

export interface IProduct {
  product: ProductModel;
  isVerified?: boolean;
  className?: string;
  onClick?: (product: ProductModel) => void;
}

export const Product: React.FC<IProduct> = ({
  product,
  isVerified = false,
  className = '',
  onClick,
}) => {
  const defaultProduct: ProductModel = {
    id: '-1',
    accountId: '-1',
    name: 'Unknown Product',
  };

  const { name, defaultImageUrl, brand, owner } = product || defaultProduct;

  const handleOnClick = (product: ProductModel) => {
    if (product?.id && product.id !== '-1' && onClick) {
      onClick(product);
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.container__content}>
        <PlaceholderImage url={defaultImageUrl} alt={name} onClick={() => handleOnClick(product)} />
        <div className={styles.container__details} onClick={() => handleOnClick(product)}>
          {brand && <Brand name={brand} isVerified={isVerified} />}
          <h3 className={styles.title}>{name}</h3>
          {!isVerified && owner && (
            <div className={styles.user}>
              <span className={styles.user__at}>@</span>
              <span className={styles.user__username}>{owner.username}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
