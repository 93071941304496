import React from 'react';

import styles from './OrderItem.module.scss';
import { Quantity } from '../../../../components/Quantity';
import { OrderLineItem } from '../../../../state/models/orderLineItem.model';
import { useTranslation } from 'react-i18next';

interface IProps {
  item: OrderLineItem;
  onChangeQuantity?: (id: string, quantity: number) => void;
  onDelete?: (id: string) => void;
  onClick?: (item: OrderLineItem) => void;
}

const OrderItem: React.FC<IProps> = ({
  item,
  onChangeQuantity = undefined,
  onDelete = undefined,
  onClick = undefined,
}) => {
  const {
    id = item.id,
    title: name,
    subline = undefined,
    price = undefined,
    quantity = 1,
    isApproxPrice = true,
    defaultImageUrl = undefined,
    options = undefined,
  } = item;

  const { t } = useTranslation(['common']);

  const changeQuantity = (newQuantity: number) => {
    if (onChangeQuantity && newQuantity !== quantity) {
      onChangeQuantity(id, newQuantity);
    }
  };

  const formatPrice = (value: number) => {
    const result = value.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${result} €`;
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <>
      <div className={styles.container} onClick={handleClick}>
        {defaultImageUrl && (
          <img className={styles.container__image} src={defaultImageUrl} alt={name} />
        )}
        <div className={styles.container__description}>
          <h3 className={styles.container__title}>{name}</h3>
          {subline && <h3 className={styles.container__subtitle}>{subline}</h3>}
          {options && (
            <div className={styles.container__options}>
              {options.map((option, key) => (
                <span key={key}>
                  {option.name}
                  {key + 1 < options.length && ', '}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className={styles.container__quantity}>
          <Quantity
            quantity={quantity}
            min={1}
            max={1000}
            onChangeQuantity={changeQuantity}
            onDelete={handleDelete}
          />
        </div>
        <div className={styles.container__price}>
          <small>{t(`common:lineItem.price`)}</small>
          <span>
            {!price && <span>-</span>}
            {price && isApproxPrice && <span>≈</span>}
            {price && formatPrice(price)}
          </span>
        </div>
        <div className={styles.container__total}>
          <small>{t(`common:lineItem.subTotal`)}</small>
          <span>
            {!price && <span>-</span>}
            {price && isApproxPrice && <span>≈</span>}
            {price && formatPrice(quantity * price)}
          </span>
        </div>
      </div>
    </>
  );
};

export default OrderItem;
