import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import SearchBar from '../../../../components/SearchBar';
import { SearchResult } from '../../../../state/models/searchResult.model';
import { RecommendedProductList } from '../../../board/pages/BoardListPage/components/RecommendedProductList';
import SearchProductList from '../../../board/pages/BoardListPage/components/SearchProductList';

import styles from './SearchControl.module.scss';

const config = {
  SEARCH_DELAY_IN_MS: 500,
};

interface IProps {
  message?: string;
  className?: string;
  suggestItem?: boolean;
  onClickRecommendation?: (item: SearchResult) => void;
  onClickSearchResult?: (item: SearchResult) => void;
  onChangeSearchMode?: (isSearchMode: boolean) => void;
}

const SearchControl: React.FC<IProps> = ({
  className = '',
  suggestItem,
  onClickRecommendation,
  onClickSearchResult,
  onChangeSearchMode,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermDebounced, setSearchTermDebounced] = useState('');
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
  const [isLoading] = useState(false);

  useEffect(() => {
    if (onChangeSearchMode) {
      onChangeSearchMode(isSearchMode);
    }
  }, [isSearchMode, onChangeSearchMode]);

  const handleOnFocus = () => {
    setIsSearchMode(true);
    window.scrollTo(0, 0);
  };

  const resetSearchResults = () => {
    if (searchResult) {
      setSearchResult([]);
    }
  };

  const handleOnCancel = () => {
    resetSearchResults();
    setIsSearchMode(false);
    setSearchTerm('');
    window.scrollTo(0, 0);
  };

  const onSearch = (searchTerm: string) => {
    if (searchTerm) {
      // searchProduct(searchTerm);
      setSearchTermDebounced(searchTerm);
    } else {
      resetSearchResults();
    }
  };

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      // setValue(value);
      onSearch(value as string);
    },
    // delay in ms
    config.SEARCH_DELAY_IN_MS
  );

  const handleChangeSearchTerm = (value: string) => {
    if (value !== searchTerm) {
      setSearchTerm(value);
      debounced(value);
      resetSearchResults();
    }
  };

  const onSearchCancel = () => {
    resetSearchResults();
    setIsSearchMode(false);
    setSearchTerm('');
    window.scrollTo(0, 0);
  };

  const handleOnClickRecommendation = (item: SearchResult) => {
    // (item) => addProduct(item);
    // console.log('recommendation?', item);
    if (onClickRecommendation) {
      onClickRecommendation(item);
    }

    onSearchCancel();
  };

  const handleOnClickSearchResult = (item: SearchResult) => {
    // (item) => addProduct(item);
    // console.log('searchResult?', item);
    if (onClickSearchResult) {
      onClickSearchResult(item);
    }

    onSearchCancel();
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <SearchBar
        showCancel={isSearchMode}
        showScan={!isSearchMode}
        showIcon
        onFocus={handleOnFocus}
        onChangeSearchTerm={handleChangeSearchTerm}
        onCancel={handleOnCancel}
        translations={{ placeholder: 'Produkte suchen' }}
        value={searchTerm}
      />
      {!searchTerm && isSearchMode && (
        <RecommendedProductList onClick={handleOnClickRecommendation} />
      )}
      {searchTerm && isSearchMode && (
        <SearchProductList
          headline={'Suchergebnis'}
          term={searchTermDebounced}
          isLoading={isLoading}
          suggestItem={suggestItem}
          onClick={handleOnClickSearchResult}
        />
      )}
    </div>
  );
};

export default SearchControl;
