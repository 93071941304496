import { Order } from '../models/order.model';
import { Reducer } from 'react';
import { Action } from '../actions';
import { OrderLineItem } from '../models/orderLineItem.model';

const calculateTotal = (items: OrderLineItem[]) => {
  let result = 0;

  items.forEach((item) => {
    if (item.price) {
      result += item.quantity * item.price;
    }
  });

  return result;
};

const calculateQuantity = (items: OrderLineItem[]) => {
  let result = 0;

  items.forEach((item) => {
    if (item.quantity) {
      result += item.quantity;
    }
  });

  return result;
};

export const orderReducer: Reducer<Order, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'ADD_ORDER': {
      const lineItems = [payload.item, ...state.lineItems];

      return {
        ...state,
        lineItems,
        lineItemCount: calculateQuantity(lineItems),
        lineItemTotal: calculateTotal(lineItems),
      };
    }

    case 'UPDATE_ORDER': {
      const lineItems = [...state.lineItems];

      return {
        ...state,
        lineItems: lineItems.map((item) => {
          if (item.id === payload.item.id) {
            return payload.item;
          } else {
            return item;
          }
        }),
        lineItemCount: calculateQuantity(lineItems),
        lineItemTotal: calculateTotal(lineItems),
      };
    }

    case 'SET_QUANTITY': {
      const lineItems = state.lineItems.map((item) => {
        if (item.id === payload.id) {
          item.quantity = payload.quantity;
          return item;
        } else {
          return item;
        }
      });

      return {
        ...state,
        lineItems,
        lineItemCount: calculateQuantity(lineItems),
        lineItemTotal: calculateTotal(lineItems),
      };
    }

    case 'TOGGLE_ORDER': {
      const lineItems = [...state.lineItems];

      return {
        ...state,
        lineItems: lineItems.map((item) => {
          if (item.id === payload.id) {
            item.isArchived = !item.isArchived;
            return item;
          } else {
            return item;
          }
        }),
        lineItemCount: calculateQuantity(lineItems),
        lineItemTotal: calculateTotal(lineItems),
      };
    }

    case 'DELETE_ORDER': {
      const lineItems = state.lineItems.filter((item) => item.id !== payload.id);

      return {
        ...state,
        lineItems,
        lineItemCount: calculateQuantity(lineItems),
        lineItemTotal: calculateTotal(lineItems),
      };
    }

    case 'CLEAR_CART': {
      const lineItems: OrderLineItem[] = [];

      return {
        ...state,
        lineItems,
        lineItemCount: calculateQuantity(lineItems),
        lineItemTotal: calculateTotal(lineItems),
      };
    }

    default:
      return state;
  }
};
