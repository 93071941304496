import React, { useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import styles from '../../Common.module.scss';
import SearchControl from '../../components/SearchControl';
import { SearchResult } from '../../../../state/models/searchResult.model';
import { Helmet } from 'react-helmet';
import { appConfig } from '../../../../common/config';
import { Button, Headline } from '../../../../components';
import { useStateValue } from '../../../../state';
// import { useBreakpoint } from '../../../../hooks/useBreakpoint.hook';

const STAGE = appConfig.stage;

const HomePage: React.FC<RouteComponentProps> = () => {
  const [{ user: userState }] = useStateValue();
  const { t } = useTranslation();
  // const breakpoint = useBreakpoint();
  const [isSearchMode, setIsSearchMode] = useState(false);

  const handleOnClick = (item: SearchResult) => {
    navigate(`/products/${item.accountId}/${item.id}`);
  };

  const handleChangeSearchMode = (isSearchMode: boolean) => {
    setIsSearchMode(isSearchMode);
  };

  return (
    // <Layout
    //   showHeader={!(isSearchMode && (breakpoint === 'xs' || breakpoint === 'sm'))}
    //   showFooter={!isSearchMode}
    // >
    <>
      <Helmet>
        <title>Ordist {STAGE === 'PROD' ? '-' : `(${STAGE}) -`} Let&apos;s we-commerce</title>
      </Helmet>
      <SearchControl
        message="Hello World!"
        suggestItem={false}
        onClickRecommendation={handleOnClick}
        onClickSearchResult={handleOnClick}
        onChangeSearchMode={handleChangeSearchMode}
      />
      {!isSearchMode && (
        <div>
          <Headline>
            {!userState.displayName
              ? t(`home:headline`)
              : t(`home:headline_greeting`, { displayName: userState.displayName })}
          </Headline>
          <div>
            <div className={styles['home__home--message']}>{t(`home:message`)}</div>
            <div className={styles['home__home--claim']}>{t(`home:claim`)}</div>
            <Button
              // className="button primary small small-only-expanded"
              isCTA={true}
              onClick={() => navigate('/boards')}
            >
              {t(`home:buttons.order`)}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
