import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';

// import { useStateValue } from '../../../../state';
import { ActionOrCancel, Headline, Hint, Label, Spinner, TextInput } from '../../../../components';

import styles from '../../Common.module.scss';
import { GET_ME } from '../../../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';

interface IView {
  displayName?: string;
}

const View: React.FC<IView> = ({ displayName = '' }) => {
  // const handleOnAction = () => {};

  const handleOnCancel = () => {
    navigate('/profile');
  };

  // const handleOnAction = () => {
  //   // const name = 'Test User';
  //   dispatch({ type: 'USER_PROFILE_UPDATE', payload: { displayName } });
  //   navigate('/profile');
  // };

  return (
    <>
      <Helmet>
        <title>Edit User Profile | ordist.com</title>
      </Helmet>
      <Headline className={styles['mt-2']}>Edit User Profile</Headline>
      <div className={`${styles.container} ${styles['mt-4']}`}>
        <div>
          <Label value="Name">
            <TextInput placeholder="Name" value={displayName} /*onChange={setDisplayName}*/ />
            <Hint>
              Your name will help others to identify you, so please use something like your full,
              abbreviated or nickname.
            </Hint>
          </Label>
        </div>

        {/* {user?.isSignedIn && (
          <>
            <div>
              <Label value="Username">
                <TextInput placeholder="Username" />
                <Hint>
                  Your name will help others to identify you, so please use something like your
                  full, abbreviated or nickname.
                </Hint>
              </Label>
            </div>
            <div>
              <Label value="Bio">
                <TextArea placeholder="Something about you" />
                <Hint>
                  Your name will help others to identify you, so please use something like your
                  full, abbreviated or nickname.
                </Hint>
              </Label>
            </div>
            <div>
              <Label value="Website">
                <TextInput placeholder="Website" />
                <Hint>
                  Your name will help others to identify you, so please use something like your
                  full, abbreviated or nickname.
                </Hint>
              </Label>
            </div>
          </>
        )} */}
        <div className={`${styles['mt-6']}`}>
          <ActionOrCancel
            /*onAction={handleOnAction}*/ onCancel={handleOnCancel}
            actionDisabled={true}
          />
        </div>
      </div>
    </>
  );
};

// interface IProps extends RouteComponentProps {}

const ProfileEditPage: React.FC<RouteComponentProps> = () => {
  // const [{ user } /*, dispatch*/] = useStateValue();
  const { loading, error, data } = useQuery(GET_ME);

  if (loading) {
    return <Spinner inverse={true} />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const user = data.getMe;

  return <View displayName={user.displayName} />;
};

export default ProfileEditPage;
