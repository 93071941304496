import React from 'react';
import ReactDOM from 'react-dom';
// import Amplify from '@aws-amplify/core';
// import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import reportWebVitals from './reportWebVitals';

import './assets/sass/init.scss';
import './index.scss';
import { App } from './components';
import './i18n';
import ReactGA from 'react-ga';
import { StateProvider } from './state';
import { appConfig } from './common/config';

const trackingId = appConfig.googleAnalytics;
const appBasePath = appConfig.appBasePath;

ReactGA.initialize(trackingId);

const amplifyConfig = {
  Auth: {
    identityPoolId: appConfig.identityPoolId,
    region: appConfig.awsRegion,
    userPoolId: appConfig.userPoolId,
    userPoolWebClientId: appConfig.userPoolWebClientId,
    oauth: {
      domain: appConfig.userPoolDomain,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: `http://localhost:3000/,https://${appBasePath}/`,
      redirectSignOut: `http://localhost:3000/logout/,https://${appBasePath}/logout/`,
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
  },
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: true,
    // OPTIONAL - Allow recording session events. Default is true.
    autoSessionRecord: true,
    AWSPinpoint: {
      // Amazon Pinpoint App Client ID
      appId: appConfig.pinpointAppClientId,
      // Amazon service region
      region: appConfig.awsRegion,
      mandatorySignIn: false,
    },
  },
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = amplifyConfig.Auth.oauth.redirectSignIn.split(',');

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = amplifyConfig.Auth.oauth.redirectSignOut.split(',');

const updatedAmplifyConfig = {
  ...amplifyConfig,
  Auth: {
    ...amplifyConfig.Auth,
    oauth: {
      ...amplifyConfig.Auth.oauth,
      redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
      redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    },
  },
};

// Initialize Amplify
// Amplify.configure(updatedAmplifyConfig);

// I added this, because otherwise the auth module is removed by optimization
// but it's needed, as Amplify depends on it?! 🙄
Auth.configure(updatedAmplifyConfig);

// Record an event
// Analytics.record('some-event-name');

// Record a custom event
// Analytics.record({
//   name: 'Album',
//   attributes: { genre: 'Rock', year: '1989' },
// });

// Analytics.updateEndpoint({
//   // address: 'xxxxxxx', // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
//   // attributes: {
//   //   // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
//   //   hobbies: ['piano', 'hiking'],
//   // },
//   // channelType: 'APNS', // The channel type. Valid values: APNS, GCM
//   demographic: {
//     //   appVersion: 'xxxxxxx', // The version of the application associated with the endpoint.
//     appVersion: 'Ordist PWA ' + appConfig.version,
//     //   locale: 'xxxxxx', // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
//     //   make: 'xxxxxx', // The manufacturer of the endpoint device, such as Apple or Samsung.
//     //   model: 'xxxxxx', // The model name or number of the endpoint device, such as iPhone.
//     //   modelVersion: 'xxxxxx', // The model version of the endpoint device.
//     //   platform: 'xxxxxx', // The platform of the endpoint device, such as iOS or Android.
//     //   platformVersion: 'xxxxxx', // The platform version of the endpoint device.
//     //   timezone: 'xxxxxx', // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
//   },
//   // location: {
//   //   city: 'xxxxxx', // The city where the endpoint is located.
//   //   country: 'xxxxxx', // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
//   //   latitude: 0, // The latitude of the endpoint location, rounded to one decimal place.
//   //   longitude: 0, // The longitude of the endpoint location, rounded to one decimal place.
//   //   postalCode: 'xxxxxx', // The postal code or zip code of the endpoint.
//   //   region: 'xxxxxx', // The region of the endpoint location. For example, in the United States, this corresponds to a state.
//   // },
//   // metrics: {
//   //   // Custom metrics that your app reports to Amazon Pinpoint.
//   // },
//   // /** Indicates whether a user has opted out of receiving messages with one of the following values:
//   //  * ALL - User has opted out of all messages.
//   //  * NONE - Users has not opted out and receives all messages.
//   //  */
//   // optOut: 'ALL',
//   // // Customized userId
//   // userId: 'XXXXXXXXXXXX',
//   // User attributes
//   // userAttributes: {
//   // interests: ['football', 'basketball', 'AWS'],
//   // ...
//   // },
// });

setTimeout(function () {
  ReactDOM.render(
    <StateProvider>
      <App />
    </StateProvider>,
    document.getElementById('root')
  );
}, 0);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
