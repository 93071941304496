import React from 'react';
import BoardItemModel from '../../modules/board/models/boardItem.model';
import { Brand } from '../Brand';
import Placeholder from '../icons/Placeholder';

import styles from './ItemDetails.module.scss';

interface IProps {
  item: BoardItemModel;
}

const ItemDetails: React.FC<IProps> = ({ item }) => {
  const { name, description, gtin, defaultImageUrl, brand } = item;

  return (
    <div className={styles.container}>
      <div>
        {defaultImageUrl ? (
          <img className={styles.container__image} src={defaultImageUrl} alt={name} />
        ) : (
          <div className={`${styles.container__flex}`}>
            <div className={`${styles.container__image}`}>
              <Placeholder className={styles.container__placeholder} />
            </div>
          </div>
        )}
      </div>
      <div className={styles.container__flex}>
        {brand && <Brand name={brand} />}
        <h1>{name}</h1>
        <div>{description}</div>
        <div>{gtin}</div>
      </div>
    </div>
  );
};

export default ItemDetails;
