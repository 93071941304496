import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import Auth from '@aws-amplify/auth';

// import { Spinner } from '../../../../components';
// import UserModel from '../../../../models/user.model';
import { useStateValue } from '../../../../state';

// import { GET_ME } from '../../../../graphql/queries';
// import { useQuery } from '@apollo/react-hooks';
import ListModel from '../../../board/models/list.model';
import UserMenu from '../../components/UserMenu';
import Logo from '../../../../components/Logo';

import styles from '../../Common.module.scss';

interface IView {
  // user: UserModel;
  isSignedIn: boolean;
  selectedBoard?: ListModel;
  onClick?: () => void;
}

const View: React.FC<IView> = ({ /*user,*/ isSignedIn, selectedBoard, onClick }) => {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleOnLogout = () => {
    Auth.signOut();
    handleOnClick();
  };

  return (
    <UserMenu>
      <div className={styles.spacer__top}>
        <div>
          <Logo className={styles.logo__image} />
        </div>
        {selectedBoard && <div className={styles.board__selected}>{selectedBoard.name}</div>}
        <div className={styles.spacer__top}>
          <Link to="/" className={styles.navigation__link} onClick={handleOnClick}>
            Home
          </Link>
          <Link to="/boards" className={styles.navigation__link} onClick={handleOnClick}>
            Lists
          </Link>
          {/* <Link to="/orders" className={styles.navigation__link} onClick={handleOnClick}>
            Orders
          </Link> */}
          <Link to="/products" className={styles.navigation__link} onClick={handleOnClick}>
            Products
          </Link>
          {/* <Link to="/stores" className={styles.navigation__link} onClick={handleOnClick}>
            Stores
          </Link> */}
          <Link to="/profile" className={styles.navigation__link} onClick={handleOnClick}>
            Profile
          </Link>
        </div>
        <div>
          {isSignedIn && (
            <button className="button small primary" onClick={handleOnLogout}>
              Sign out
            </button>
          )}
          {!isSignedIn && (
            <>
              <Link to="/login" className="button small" onClick={handleOnClick}>
                Sign in
              </Link>
            </>
          )}
        </div>
        {/* <div className={styles.info__id}>ID: {user.id}</div> */}
      </div>
    </UserMenu>
  );
};
interface IProps extends RouteComponentProps {
  onClick?: () => void;
}

const MenuPage: React.FC<IProps> = ({ onClick }) => {
  const [{ user: userState }] = useStateValue();
  // const { loading, error, data } = useQuery(GET_ME);

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // if (loading) {
  //   return <Spinner inverse={true} />;
  // }

  // if (error) {
  //   return <div>{error.message}</div>;
  // }

  // const user = data.getMe;

  return (
    <View
      // user={{ id: user.id }}
      selectedBoard={userState?.selectedBoard}
      isSignedIn={userState?.isSignedIn}
      onClick={handleOnClick}
    />
  );
};

export default MenuPage;
