import React, { ReactNode } from 'react';

import Header from '../Header';
// import { Footer } from '..';

// import { appConfig } from '../../common/config';
import { BottomNavigation } from '../BottomNavigation';
import { useStateValue } from '../../state';

import styles from './Layout.module.scss';

interface IProps {
  children: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

const Layout: React.FC<IProps> = ({ children, showHeader = true, showFooter = true }) => {
  const [{ app }] = useStateValue();

  return (
    <div className="App">
      <header>
        {showHeader && <Header isDetailMode={app.isDetailMode} onMenuClick={app.onMenuClick} />}
      </header>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className="grid-container">
            <div className="grid-x margin-x">
              <div className="cell small-12 medium-offset-2 medium-8">
                <div>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {showFooter && <Footer version={appConfig.version} />} */}
      {showFooter && <BottomNavigation location={app.location} />}
    </div>
  );
};

export default Layout;
