import React from 'react';

import { ProductItem } from '../Product';
import styles from './ProductList.module.scss';
import { Product } from '../../../../state/models/product.model';

interface IProps {
  products: Product[];
  onAddToCart?: (product: Product, quantity: number) => void;
}

const ProductList: React.FC<IProps> = ({ products, onAddToCart }: IProps) => {
  const handleAddToCart = (product: Product, quantity: number) => {
    if (onAddToCart) {
      onAddToCart(product, quantity);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        {products.length === 0 && <div>Aktuell sind keine Produkte vorhanden</div>}
        {products.length > 0 &&
          products.map((product) => {
            return (
              <div key={product.id}>
                <ProductItem product={product} onAddToCart={handleAddToCart} />
                <hr />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductList;
