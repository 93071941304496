import { navigate } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';
import { scanImageData } from 'zbar.wasm';

import styles from './BarcodeScanner.module.scss';

const SCAN_PROID_MS = 400;
interface IProps {
  message?: string;
  className?: string;
}

const BarcodeScanner: React.FC<IProps> = ({ className = '' }) => {
  const [counter, setCounter] = useState(0);
  const [isStreamInit, setIsStreamInit] = useState(false);

  const video = useRef<HTMLVideoElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);

  const constraints = {
    audio: false,
    video: {
      facingMode: 'environment',
    },
    width: { max: 640 },
    height: { max: 640 },
  };

  useEffect(() => {
    let stream: MediaStream;

    const getStream = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        handleSuccess(stream);
      } catch (err: any) {
        handleError(err);
      }
    };

    getStream();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    const timer1 = setTimeout(async () => {
      setCounter(counter + 1);
      await attemptDecode();
    }, SCAN_PROID_MS);

    return () => {
      clearTimeout(timer1);
    };
  }, [counter]);

  const handleSuccess = (stream: any) => {
    if (video && video.current) {
      video.current.srcObject = stream;
      setIsStreamInit(true);
    }
  };

  const attemptDecode = async () => {
    if (isStreamInit) {
      try {
        // if (canvas && canvas.current && video && video.current) {
        if (video && video.current && canvas && canvas.current) {
          // const canvas = canvas.current; //document.createElement('canvas');
          const width = 640; //video.current.videoWidth;
          const height = 480; //video.current.videoHeight;
          canvas.current.width = width;
          canvas.current.height = height;

          // const ctx = canvas.current.getContext('2d');
          const ctx = canvas.current.getContext('2d');
          if (ctx) {
            // canvas.current.width = video.current.videoWidth;
            // canvas.current.height = video.current.videoHeight;
            canvas.current.width = 640; // video.current.videoWidth;
            canvas.current.height = 480; // video.current.videoHeight;

            ctx.drawImage(video.current, 0, 0, canvas.current.width, canvas.current.height);

            const imgData = ctx.getImageData(0, 0, canvas.current.width, canvas.current.height);

            if (imgData.data) {
              // this.decoder.postMessage(imgData);
              const res = await scanImageData(imgData);

              if (res) {
                for (let i = 0; i < res.length; ++i) {
                  const sym = res[i];
                  const gtin = sym.decode();
                  navigate(`/products/findByGtin/${gtin}`);
                }
              }
            }
          }
        }
      } catch (err: any) {
        if (err.name == 'NS_ERROR_NOT_AVAILABLE')
          setTimeout(() => {
            attemptDecode();
          }, 0);
        console.log('Error');
        console.log(err);
      }
    }
  };

  const handleError = (error: Error) => {
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <button className="button small-only-expanded" onClick={() => navigate(`/`)}>
        Close
      </button>
      <div>
        <div className={styles.container__video}>
          <video playsInline autoPlay ref={video}></video>
          <canvas id="qr-canvas" className={styles.barcode__canvas} ref={canvas}></canvas>
        </div>
      </div>
    </div>
  );
};

export default BarcodeScanner;
