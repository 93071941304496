import React, { useState, MouseEvent, useEffect } from 'react';

import styles from './Quantity.module.scss';

interface IProps {
  quantity?: number;
  min?: number;
  max?: number;
  removeOnMin?: boolean;
  onChangeQuantity?: (quantity: number) => void;
  onDelete?: () => void;
}

export const isValid = (quantity: string, min?: number, max?: number): boolean => {
  const value = Number(quantity);

  return (
    quantity !== '' &&
    !isNaN(value) &&
    (min === undefined || value >= min) &&
    (max === undefined || value <= max)
  );
};

const Quantity: React.FC<IProps> = ({
  quantity = 1,
  min = undefined,
  max = undefined,
  removeOnMin = true,
  onChangeQuantity = undefined,
  onDelete = undefined,
}) => {
  const [value, setValue] = useState(quantity);
  const [internalQuantity, setInternalQuantity] = useState(quantity.toString());

  const showRemove = removeOnMin === true && value === min;

  useEffect(() => {
    setValue(quantity);
    setInternalQuantity(quantity.toString());
  }, [quantity]);

  const handleChange = (newValue: string) => {
    setInternalQuantity(newValue);

    if (isValid(newValue, min, max)) {
      setValue(Number(newValue));

      if (onChangeQuantity) {
        onChangeQuantity(Number(newValue));
      }
    }
  };

  const handleBlur = () => {
    const quantity = Number(internalQuantity);
    if (min && quantity < min) {
      handleChange(min.toString());
    } else if (max && quantity > max) {
      handleChange(max.toString());
    } else {
      handleChange(value.toString());
    }
  };

  const handleIncrement = (event: MouseEvent) => {
    event.stopPropagation();

    if (!max || (max && value < max)) {
      handleChange((value + 1).toString());
    }
  };

  const handleDecrement = (event: MouseEvent) => {
    event.stopPropagation();

    if (!min || (min && value > min)) {
      handleChange((value - 1).toString());
    }
  };

  const handleRemove = (event: MouseEvent) => {
    event.stopPropagation();

    if (onDelete) {
      onDelete();
    }
  };

  return (
    <>
      <div className={styles.container}>
        {showRemove ? (
          <div className={styles.container__remove} onClick={handleRemove} data-testid="remove" />
        ) : (
          <div
            className={styles.container__minus}
            onClick={handleDecrement}
            data-testid="decrement"
          />
        )}
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          value={internalQuantity}
          autoComplete="off"
          className={styles.container__quantity}
          data-testid="quantity"
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
        />
        <div className={styles.container__plus} onClick={handleIncrement} data-testid="increment" />
      </div>
    </>
  );
};

export default Quantity;
