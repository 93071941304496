import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from '..';
import ProductList from '../../modules/product/components/ProductList';
import { IProductListItem } from '../../modules/product/components/ProductListItem/ProductListItem';
import ProductModel from '../../modules/product/models/product.model';

import { SearchResult } from '../../state/models/searchResult.model';

import styles from './SearchResults.module.scss';

interface IProps {
  headline?: string;
  term?: string;
  items?: IProductListItem[];
  isLoading?: boolean;
  suggestItem?: boolean;
  onClick?: (item: SearchResult) => void;
}

const highlight = (value: string, highlight: string) => {
  return value.replace(highlight, `<strong>${highlight}</strong>`);
};

const capitalize = (value: string) => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const SearchResults: React.FC<IProps> = ({
  headline = '',
  term = '',
  items = undefined,
  isLoading = false,
  suggestItem = true,
  onClick = undefined,
}) => {
  const { t } = useTranslation(['common']);
  term = capitalize(term.trim());

  const handleOnClick = (product: ProductModel) => {
    const item = {
      id: product.id,
      accountId: product.accountId,
      title: product.name,
      ean: product.gtin,
      defaultImageId: product.defaultImageId,
      defaultImageUrl: product.defaultImageUrl,
      product,
    };

    if (onClick) {
      onClick(item);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.list__header}>{headline}</div>
      {suggestItem && term && (
        <div
          id="add_term"
          className={styles.list__add}
          onClick={() =>
            handleOnClick({
              id: `temp_${uuidv4()}`,
              accountId: '',
              name: term,
            })
          }
          dangerouslySetInnerHTML={{
            __html: highlight(t(`common:search.add`, { term }), term),
          }}
        />
      )}
      {isLoading && (
        <div>
          <Spinner inverse={true} />
        </div>
      )}
      {
        !isLoading && (
          <ProductList items={items} onClick={(product: ProductModel) => handleOnClick(product)} />
        )
        // items?.map((item: SearchResult) => (
        //   <div key={item.id} className={styles.list__item} onClick={() => handleOnClick(item)}>
        //     {item.display}
        //   </div>
        // ))
      }
    </div>
  );
};

export default SearchResults;
