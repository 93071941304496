import React from 'react';

import styles from './Spinner.module.scss';

interface IProps {
  message?: string;
  className?: string;
  inverse?: boolean;
}

const Spinner: React.FC<IProps> = ({ className = '', inverse = false }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={inverse ? styles.spinner__inverse : styles.spinner} />
    </div>
  );
};

export default Spinner;
