import { env } from '../util/environment.util';

export const appConfig = {
  stage: env('REACT_APP_STAGE'),
  appBasePath: env('REACT_APP_BASE_PATH'),
  apiBasePath: env('REACT_APP_API_BASE_PATH'),
  graphqlBasePath: env('REACT_APP_GRAPHQL_BASE_PATH'),
  userPoolDomain: env('REACT_APP_USER_POOL_DOMAIN'),
  userPoolId: env('REACT_APP_USER_POOL_ID'),
  identityPoolId: env('REACT_APP_IDENTITY_POOL_ID'),
  userPoolWebClientId: env('REACT_APP_USER_POOL_WEB_CLIENT_ID'),
  pinpointAppClientId: env('REACT_APP_PINPOINT_APP_CLIENT_ID'),
  awsRegion: env('REACT_APP_AWS_REGION'),
  simulatedNetworkDelay: 0,
  version: env('REACT_APP_VERSION'),
  googleAnalytics: env('REACT_APP_GA'),
};
