import React from 'react';

import styles from './Error.module.scss';

export interface IError {
  children?: React.ReactNode;
  className?: string;
}

export const Error: React.FC<IError> = ({ children, className = '' }) => {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
};

export default Error;
