import React from 'react';

interface IProps {
  className?: string;
}

const Product: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      className={className}
    >
      <path d="M13.267 17.5a1.084 1.084 0 0 0 1.083-1.083V14.25a1.084 1.084 0 0 0-2.167 0v2.167a1.083 1.083 0 0 0 1.084 1.083Zm-4.334 0a1.084 1.084 0 0 0 1.084-1.083V14.25a1.083 1.083 0 1 0-2.167 0v2.167A1.084 1.084 0 0 0 8.933 17.5Zm9.75-13h-1.495L15.314.762A1.082 1.082 0 0 0 13.48.488a1.083 1.083 0 0 0-.094 1.25L14.762 4.5H7.438l1.376-2.763A1.083 1.083 0 0 0 6.886.762L5.012 4.5H3.517a3.25 3.25 0 0 0-.997 6.327l.802 8.081a3.25 3.25 0 0 0 3.25 2.925h9.078a3.25 3.25 0 0 0 3.25-2.925l.802-8.081A3.25 3.25 0 0 0 18.683 4.5Zm-1.96 14.192a1.083 1.083 0 0 1-1.084.975H6.561a1.083 1.083 0 0 1-1.083-.975L4.708 11h12.784l-.77 7.692Zm1.96-9.859H3.517a1.083 1.083 0 1 1 0-2.166h15.166a1.083 1.083 0 1 1 0 2.166Z" />
    </svg>
  );
};

export default Product;
