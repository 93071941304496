import React from 'react';
import { FormikProvider, useFormik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import styles from './Form.module.scss';
import { ActionOrCancel } from '..';

export enum SubmitMode {
  Create = 'create',
  Update = 'update',
}

export interface IForm {
  schema: (t: TFunction) => any;
  initialValues?: any;
  mode?: SubmitMode;
  children?: React.ReactNode;
  className?: string;
  onSubmit?: (form: any) => Promise<void>;
  onCancel?: () => void;
}

export const Form: React.FC<IForm> = ({
  schema,
  initialValues = {},
  mode = SubmitMode.Create,
  children,
  className = '',
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation(['common', 'product']);

  const formik = useFormik({
    initialValues,
    validationSchema: schema(t),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      await handleSubmit(values);
      setSubmitting(false);
    },
  });

  const handleSubmit = async (values: any) => {
    if (onSubmit) {
      await onSubmit(values);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} noValidate className={`${styles['mt-1']}`}>
          {children}
          <div className={styles['mt-6']}>
            <ActionOrCancel
              actionLabel={
                mode === SubmitMode.Create ? t(`product:buttons.add`) : t(`product:buttons.update`)
              }
              cancelLabel={t(`common:buttons.cancel`)}
              // onAction={handleSubmit}
              onCancel={handleCancel}
              actionDisabled={
                formik.isSubmitting ||
                (mode === SubmitMode.Update && formik.values === initialValues)
              }
              isLoading={formik.isSubmitting}
            />
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};

export default Form;
