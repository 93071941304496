import { Reducer } from 'react';

import { App } from '../models/app.model';
import { Action } from '../actions';

export const appReducer: Reducer<App, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'APP_LOCATION_CHANGE': {
      return {
        ...state,
        location: payload?.location?.pathname,
      };
    }
    case 'APP_DETAIL_MODE': {
      return {
        ...state,
        isDetailMode: payload.isDetailMode ?? false,
        onMenuClick: payload.onClick,
      };
    }
    default:
      return state;
  }
};
