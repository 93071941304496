import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { readStoreById } from '../../../../services/store.service';
import { OrderLineItem } from '../../../../state/models/orderLineItem.model';

import styles from '../../Store.module.scss';

interface IProps extends RouteComponentProps {
  storeId?: string;
}

const StoreReviewPage: React.FC<IProps> = ({ storeId = undefined }) => {
  if (!storeId) {
    return <></>;
  }

  const store = readStoreById(storeId);

  if (store === undefined) {
    return <></>;
  }

  const items: OrderLineItem[] = [];

  store.products?.map((product) => {
    items.push({
      id: product.id,
      title: product.name,
      subline: '',
      quantity: 0,
      price: product.price ? product.price : 0.0,
      isApproxPrice: false,
    });
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1>Prüfen und bestellen</h1>
        </div>
        <div className={styles.container}>
          <div>
            Zusammenfassung aller zuvor erfassten Daten
            <br />
            <br />
            <ul className="text-left">
              <li>Produkte</li>
              <li>Liefermethode</li>
              <li>Persönliche Daten</li>
              <li>Zahlungsart</li>
            </ul>
          </div>
          <div>
            <button
              className="button small-only-expanded float-right"
              onClick={() => navigate(`/stores/${storeId}/success`)}
            >
              Kostenpflichtig bestellen
            </button>
            <button
              className="button hollow small-only-expanded float-left"
              onClick={() => navigate(`/stores/${storeId}/payment`)}
            >
              Zurück zur Zahlungsart
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreReviewPage;
