import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import RadioButtonList from '../../components/RadioButtonList';
import RadioButtonModel from '../../../../models/radioButton.model';

import styles from '../../Store.module.scss';

interface IProps extends RouteComponentProps {
  storeId?: string;
}

const deliveryOptions: RadioButtonModel[] = [
  {
    id: '1',
    title: 'No delivery',
    description: "I'll take care of it by myself.",
  },
  {
    id: '2',
    title: 'Preorder Pickup',
    description: 'I order online and pick it up myself.',
  },
];

const StoreDeliveryPage: React.FC<IProps> = ({ storeId = undefined }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1>Lieferung</h1>
        </div>
        <div className={styles.container}>
          <RadioButtonList items={deliveryOptions} />
        </div>

        <div>
          <button
            className="button small-only-expanded float-right"
            onClick={() => navigate(`/stores/${storeId}/location`)}
          >
            Weiter
          </button>
          <button
            className="button hollow small-only-expanded float-left"
            onClick={() => navigate(`/stores/${storeId}/cart`)}
          >
            Zurück zum Warenkorb
          </button>
        </div>
      </div>
    </>
  );
};

export default StoreDeliveryPage;
